import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CheckCircleOutline, PriorityHigh } from "@mui/icons-material";
import { Alert, AlertColor, AlertTitle, Box, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetAccountSubscriptionsQuery } from "fond/api";
import { getCurrentProject } from "fond/project";
import { StatusTypes } from "fond/project/redux";
import { Project, Store } from "fond/types";
import { getColor } from "fond/widgets/colors";

const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      marginBottom: theme.spacing(1),
    },
    running: {
      color: "#222 !important",
      backgroundColor: getColor("yellow"),
    },
    idle: {
      color: "#999",
      backgroundColor: getColor("grey", "veryLight"),
    },
    complete: {
      color: "#444",
      backgroundColor: getColor("green", "tertiary"),
    },
    terminated: {
      color: "white",
      backgroundColor: getColor("red", "secondary"),
    },
    cancelling: {
      color: "#999",
      backgroundColor: getColor("grey", "veryLight"),
    },
    noStatus: {
      color: "#999",
      backgroundColor: getColor("grey", "veryLight"),
    },
    icon: { position: "absolute", right: 10, top: 6, fontSize: 15 },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  status: keyof typeof StatusTypes | null | undefined;
  readOnly: boolean | undefined;
  cancelStatus: string;
  onCancel(): void;
}

const SolveStatus: React.FC<IProps> = ({ classes, status, readOnly, cancelStatus, onCancel }: IProps) => {
  const statusAlerts = {
    [StatusTypes.Running]: {
      dataT: "solve-status-running",
      severity: "info",
      message: "Generating design",
    },
    [StatusTypes.Cancelling]: {
      dataT: "solve-status-cancelling",
      severity: "warning",
      message: "Cancelling...",
    },
    [StatusTypes.Cancelled]: {
      dataT: "solve-status-cancelled",
      severity: "info",
      message: "Design cancelled",
    },
    [StatusTypes.Terminated]: {
      dataT: "solve-status-terminated",
      severity: "error",
      message: "Design failed",
    },
    [StatusTypes.Complete]: {
      dataT: "solve-status-complete",
      severity: "success",
      message: "Design complete",
    },
    default: {
      dataT: "solve-status-error",
      severity: "warning",
      message: "Failed to retrieve design status.",
    },
  };

  const project = useSelector((state: Store) => getCurrentProject(state.project));
  const accountId = project?.Account.ID;
  const { refetch: refectAccountSubscriptions } = useGetAccountSubscriptionsQuery(accountId ?? skipToken);
  const statusAlert = status ? statusAlerts[status] || statusAlerts.default : statusAlerts.default;

  useEffect(() => {
    if (status) {
      if ([StatusTypes.Complete, StatusTypes.Terminated].includes(status)) {
        refectAccountSubscriptions();
      }
    }
  }, [status]);

  return (
    <Box className={classes.container}>
      {status === StatusTypes.Idle && (
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item>
            <PriorityHigh style={{ fontSize: "3em", color: "#9D9D9D" }} />
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h6">Ready!</Typography>
            <Typography variant="body2">Your design is ready to be generated.</Typography>
          </Grid>
        </Grid>
      )}
      {status !== StatusTypes.Idle && (
        <Alert
          data-testid={statusAlert.dataT}
          severity={statusAlert.severity as AlertColor}
          icon={statusAlert.severity === "success" ? <CheckCircleOutline fontSize="inherit" sx={{ color: "#2E7D32" }} /> : undefined}
        >
          <AlertTitle sx={{ fontSize: 14, mb: 0 }}>{statusAlert.message}</AlertTitle>
        </Alert>
      )}
    </Box>
  );
};

SolveStatus.displayName = "SolveStatus";
export default withStyles(customStyles)(SolveStatus);
