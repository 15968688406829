import { Badge, Box, styled, TextField, Typography } from "@mui/material";

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const InviteSearchField = styled(TextField)`
  width: 25%;
  max-width: 233px;
  align-self: flex-end;
`;

export const GridWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

export const TabBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: "50%",
    right: theme.spacing(-2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.biarri.primary.red,
  },
}));

export const ExpirationCell = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isExpired",
})<{ isExpired: boolean }>(({ theme, isExpired }) => ({
  color: isExpired ? theme.palette.biarri.primary.red : "unset",
  fontSize: 12,
  lineHeight: 2.5,

  "& > svg": {
    width: 20,
    height: 20,
    verticalAlign: "middle",
    marginRight: 6,
  },
}));
