import React from "react";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { Properties } from "fond/cityPlanner/Properties";

import { ITabProps } from "../factory";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = () => {
  return <Properties />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = () => (
  <Tooltip title="FOND Designer" arrow placement="right">
    <Edit />
  </Tooltip>
);
