import { LayerFilterOption } from "fond/map/Legend/Adornments/LayerFilter";
import { ConfigurationUpsert, GroupConfig } from "fond/types";
import { FilterConfiguration, LayerStyle } from "fond/types/ProjectLayerConfig";

import { FamilialLayerConfig, groupConfigTemplate, layerConfigTemplate, makeBuckets, OverlayIds, sublayerTemplate } from "./configuration";

export enum CbgLayerId {
  UNEMPLOYMENT_PROPORTION = "census-block-groups-unemployed-proportion",
  MEDIAN_INCOME = "census-block-groups-median-income-dollars",
}
export const cbgLayerFilterOptions: LayerFilterOption[] = [
  { ID: CbgLayerId.UNEMPLOYMENT_PROPORTION, Label: "Unemployment Rate", Type: "Option" },
  { ID: CbgLayerId.MEDIAN_INCOME, Label: "Median Income ($)", Type: "Option" },
];

export const defaultCbgFilteredLayerIds = [CbgLayerId.MEDIAN_INCOME];

const key = "census-block-groups";

// The available data is as follows:
// "demographics_median_income_dollars": 78841.0,
// "demographics_unemployed_proportion": 0.07277289836888332,
// "demographics_device_household_count": 708.0,
// "demographics_household_average_size": 2.17,
// "demographics_over_60_household_count": 409.0,
// "demographics_under_18_household_count": 146.0,
// "demographics_bachelor_degree_proportion": 0.41880969875091845,
// "demographics_high_school_diploma_proportion": 0.9301983835415136,
// "demographics_internet_subscription_household_count": 691.0

/**
 * Create the CBG overlay configuration.
 */
export const cbgOverlayConfiguration = (): { configs: ConfigurationUpsert; groupConfig: GroupConfig } => {
  const { config: employmentLayerConfig, descendants: employmentDescendants } = createUnemploymentProportionLayer();
  const { config: medianIncomeLayerConfig, descendants: medianIncomeDescendants } = createMedianIncomeLayer();

  const layerConfigs = [employmentLayerConfig, medianIncomeLayerConfig];
  const descendants = [...employmentDescendants, ...medianIncomeDescendants];

  // Create the groups
  const cbg = groupConfigTemplate(OverlayIds.DEMOGRAPHICS, layerConfigs, "Demographics", true, OverlayIds.ROOT);

  // Assemble everything together
  return { configs: [...descendants, ...layerConfigs], groupConfig: cbg };
};

const createUnemploymentProportionLayer = (): FamilialLayerConfig => {
  const buckets = makeBuckets({
    layerId: CbgLayerId.UNEMPLOYMENT_PROPORTION,
    attribute: "demographics_unemployed_proportion",
    labels: ["0", "0 - 2.68", "2.68 - 5.21", "5.21 - 9.42", "9.42+"],
    splits: [0, 0.0, 0.026807473598700244, 0.05212620027434842, 0.09416581371545547, 1.0],
    colors: ["#4dd53e", "#c9e816", "#f5c802", "#f96a00", "#d53e4f"],
    includeNullBucket: true,
  });

  const descendants = buckets.flatMap((bucket) => {
    const styles = createEmploymentStyles(bucket.id, bucket.color, bucket.filter, 0.3);
    const sublayerConfig = sublayerTemplate(bucket.id, key, CbgLayerId.UNEMPLOYMENT_PROPORTION, bucket.label, bucket.filter, styles);
    return [...styles, sublayerConfig];
  });

  const config = layerConfigTemplate(
    CbgLayerId.UNEMPLOYMENT_PROPORTION,
    key,
    "Unemployment Rate (%)",
    [],
    buckets.map(({ id }) => id),
    OverlayIds.DEMOGRAPHICS
  );

  return { config, descendants };
};

const createMedianIncomeLayer = (): FamilialLayerConfig => {
  const buckets = makeBuckets({
    layerId: CbgLayerId.MEDIAN_INCOME,
    attribute: "demographics_median_income_dollars",
    // these are the 0, 10th, ..., 90th percentiles for median income in the dataset (rounded to the nearest 100
    // maybe nearest 1000 would look a little better?). For reference, the 100th percentile is 250_001.
    splits: [0, 20000, 37300, 47000, 55400, 63800, 73250, 84800, 101200, 128800],
    colors: ["#d73027", "#f46d43", "#fee08b", "#ffffbf", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850", "#4dd53e", "#2bf015"],
    includeLastBucket: true,
    // many census block groups have no income data
    includeNullBucket: true,
  });

  const descendants = buckets.flatMap((bucket) => {
    const styles = createEmploymentStyles(bucket.id, bucket.color, bucket.filter, 0.3);
    const sublayerConfig = sublayerTemplate(bucket.id, key, CbgLayerId.MEDIAN_INCOME, bucket.label, bucket.filter, styles);
    return [...styles, sublayerConfig];
  });

  const config = layerConfigTemplate(
    CbgLayerId.MEDIAN_INCOME,
    key,
    "Median Income ($)",
    [],
    buckets.map(({ id }) => id),
    OverlayIds.DEMOGRAPHICS
  );

  return { config, descendants };
};

const createEmploymentStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity ?? 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];
