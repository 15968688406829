import React from "react";
import { ListAlt } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { FeaturesTable } from "fond/cityPlanner";

import { ITabProps } from "../factory";

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }: ITabProps) => {
  return <FeaturesTable />;
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => (
  <Tooltip title="Subareas">
    <ListAlt />
  </Tooltip>
);
