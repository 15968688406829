import React, { forwardRef, useMemo, useRef } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  DisplayedColumnsChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridState,
  ModuleRegistry,
  PaginationChangedEvent,
  SortChangedEvent,
} from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { Box, CSSObject } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { getUserPreferenceValue, useUpdateUserPreferencesMutation } from "fond/api";
import { UserPreferenceKey } from "fond/types";
import { GridId } from "fond/types/grids";
import { areWeTestingWithJest } from "fond/utils";
import { useAppSelector } from "fond/utils/hooks";

import BlockSpinner from "../BlockSpinner";

import "@ag-grid-community/styles/ag-grid.css";
import "./theme/ag-theme-compact.scss";
import "./theme/ag-theme-standard.scss";
import "./theme/ag-theme-large.scss";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  ClipboardModule,
  FiltersToolPanelModule,
  MenuModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  SetFilterModule,
]);

interface IProps extends AgGridReactProps {
  /**
   * The unique identifier of the grid. This is used to save the grid state and allow
   * the users customised state to be restored on re-rendering the grid.
   */
  id?: GridId;
  /**
   * The grid works out the best width for columns
   * @default true
   */
  containerProps?: CSSObject;
  gridOptions?: GridOptions;
  variant?: "borderless" | "outlined";
  size?: "compact" | "standard" | "large";
}

type ColumnState = Pick<GridState, "columnOrder" | "columnPinning" | "columnSizing" | "columnVisibility" | "pagination" | "sort">;
type ColumnStateEvent = DisplayedColumnsChangedEvent | ColumnResizedEvent | ColumnPinnedEvent | PaginationChangedEvent | SortChangedEvent;

const AgGrid = forwardRef<AgGridReact, IProps>(
  (
    {
      id,
      containerProps = { height: "100%", width: "100%" },
      gridOptions,
      onGridReady,
      variant = "borderless",
      size = "standard",
      ...gridProps
    }: IProps,
    ref
  ) => {
    const [updatePreference] = useUpdateUserPreferencesMutation();
    const gridApi = useRef<GridApi | null>();
    const wrapperRef = useRef<HTMLElement | null>(null);

    const defaultOptions: GridOptions = useMemo(
      () => ({
        columnMenu: "legacy",
        // Sets default column values (These can be set individually in column definitions)
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true,
          enableRowGroup: true,
        },
        sideBar: {
          toolPanels: [
            {
              id: "filters",
              labelDefault: "Filters",
              labelKey: "filters",
              iconKey: "filter",
              toolPanel: "agFiltersToolPanel",
              toolPanelParams: {
                suppressExpandAll: true,
                suppressFilterSearch: true,
              },
            },
          ],
        },
        popupParent: document.querySelector("body") || undefined,
        reactiveCustomComponents: true,
        suppressContextMenu: false,
        suppressCellFocus: true,
        suppressDragLeaveHidesColumns: true,
        suppressMenuHide: false,
        rowGroupPanelShow: "always",
        // AgGrid currently does not remove the loadingOverlay when running jest tests
        // if the data loaded is an empty array (indicating it should show the empty rows component)
        loadingOverlayComponent: areWeTestingWithJest() ? undefined : BlockSpinner,
      }),
      []
    );

    /**
     * The grid has initialised
     */
    const handleOnGridReady = (event: GridReadyEvent) => {
      gridApi.current = event.api;

      // To support popups within floating windows we need to set the popup parent
      event.api.setGridOption("popupParent", handleGetDocument().body);

      onGridReady?.(event);

      // If an id has been supplied we set the grid events that modified state we are maintaining.
      if (id) {
        event.api.setGridOption("onDisplayedColumnsChanged", handleStateUpdate);
        event.api.setGridOption("onColumnPinned", handleStateUpdate);
        event.api.setGridOption("onSortChanged", handleStateUpdate);
        event.api.setGridOption("onColumnResized", handleOnColumnResize);
        event.api.setGridOption("onPaginationChanged", handleOnPageSizeChanged);
      }
    };

    const options = { ...defaultOptions, ...gridOptions };

    /**
     * Allows overriding what document is used. Currently used by Drag and Drop.
     * We need to specify this to support loading the grid inside a floating window.
     */
    const handleGetDocument = (): Document => {
      return wrapperRef.current?.ownerDocument || window.document;
    };

    /**
     * Saves column state changes to user preferences to allow for the grid
     * state to be restore when loaded next time.
     */
    const handleStateUpdate = useDebouncedCallback((event: ColumnStateEvent) => {
      if (id) {
        const { columnOrder, columnPinning, columnSizing, columnVisibility, sort, pagination } = event.api.getState();

        updatePreference({
          Key: UserPreferenceKey.GRID_STATE,
          Subkey: id,
          Value: {
            columnOrder,
            columnPinning,
            columnSizing,
            columnVisibility,
            sort,
            pagination: { pageSize: pagination?.pageSize },
          },
        });
      }
    }, 1000);

    const handleOnColumnResize = (event: ColumnResizedEvent) => {
      if (id && event.finished) handleStateUpdate(event);
    };

    const handleOnPageSizeChanged = (event: PaginationChangedEvent) => {
      if (id && event.newPageSize) handleStateUpdate(event);
    };

    /**
     * The initialState allows the grid to be restored to a previous state. Note that we only use the initialState
     * if the grid has an id set.
     *
     * For example:
     * - Column visibility
     * - Column order
     * - Pinned Columns
     *
     * The handleStateUpdate() above determines the partial GridState we save & can therefore restore.
     */
    const initialState = useAppSelector((state) => (id ? getUserPreferenceValue(state, UserPreferenceKey.GRID_STATE, id) : undefined));

    return (
      <Box sx={containerProps} className={`ag-theme-${size} ${variant}`} data-testid="ag-grid" ref={wrapperRef}>
        <AgGridReact
          key={id}
          ref={ref}
          {...gridProps}
          initialState={initialState}
          gridOptions={options}
          onGridReady={handleOnGridReady}
          getDocument={handleGetDocument}
        />
      </Box>
    );
  }
);

AgGrid.displayName = "AgGrid";
export default AgGrid;
