import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColDef, GridOptions, IRowNode, RowDataUpdatedEvent, SelectionChangedEvent, ValueGetterParams } from "@ag-grid-community/core";
import { PlaylistAdd } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { useSnackbar } from "notistack";

import DeleteConfirmation from "fond/project/comments/DeleteConfirmation";
import { deleteAttachments as deleteAttachmentsAction, downloadAttachments, getAttachments, updateAttachments } from "fond/redux/attachments";
import { AppThunkDispatch, Attachment, AttachmentEntityType, MultiProject, Project, Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { AgGrid, NonIdealState, RenameModal } from "fond/widgets";
import {
  dateValueFormatter,
  fileSizeValueFormatter,
  LinkCellRenderer,
  MimeTypeCellRenderer,
  stringComparator,
  userValueFormatter,
} from "fond/widgets/AgGrid";
import DataGridToolbar from "fond/widgets/DataGrid/DataGridToolbar";

import AttachmentListToolbarActions from "./AttachmentListToolbarActions";

const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      paddingTop: theme.spacing(1),
    },
    spinner: {
      height: 80,
      width: 80,
    },
    spinnerContainer: {
      height: "100%",
      margin: "auto",
    },
  });
};

export interface AttachmentListProps extends WithStyles<typeof customStyles> {
  /**
   * The current project being viewed
   */
  project: Project | MultiProject;
  /**
   * The level at which the attachment will be performed
   */
  attachmentEntityType?: AttachmentEntityType;
  /**
   * The unique identifier for a feature belonging to a layer on the map
   */
  featureId?: string | null;
}

const AttachmentList: React.FC<AttachmentListProps> = ({ classes, project, attachmentEntityType = "Project", featureId }: AttachmentListProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: AppThunkDispatch = useDispatch();
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const items = useSelector((state: Store) => state.attachments.items[attachmentEntityType]);
  const [selectedItems, setSelectedItems] = useState<Attachment[]>([]);
  const [renameAttachment, setRenameAttachment] = useState<Attachment | null>(null);
  const [deleteAttachments, setDeleteAttachments] = useState<Attachment[] | null>(null);

  /**
   * Load the attachment list and provide a snackbar with a retry load action.
   */
  useEffect(() => {
    const loadAttachments = () => {
      dispatch(getAttachments(project.ID, featureId)).catch(() => {
        enqueueSnackbar("Attachments failed to load.", {
          action: (
            <Button color="primary" onClick={loadAttachments}>
              Retry
            </Button>
          ),
        });
      });
    };
    loadAttachments();
  }, []);

  /**
   * These are the toolbar action handlers. These actions are trigger from the datagrid selection toolbar.
   */

  /**
   * Open the 'View' url in a new tab for display in browser.
   */
  const handleOpenAttachment = (attachment: Attachment) => {
    if (attachment && attachment.Urls?.View) {
      const newWindow = window.open(attachment.Urls.View, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  /**
   * Initiate the download of multiple attachments.
   */
  const handleDownloadAttachments = useCallback(
    async (attachments: Attachment[]) => {
      return dispatch(downloadAttachments(attachments)).catch(() => enqueueSnackbar("Some attachments failed to download."));
    },
    [dispatch, enqueueSnackbar]
  );

  /**
   * Create and open the rename model. Dispatch an action to update attachment name.
   */
  const handleRenameAttachment = (attachment: Attachment) => {
    setRenameAttachment(attachment);
  };

  const renderRenameAttachmentModal = (attachment: Attachment) => {
    return (
      <RenameModal
        title="Rename Attachment"
        defaultValue={attachment.Name}
        message="Enter a new name for this attachment"
        onRename={async (formData) => {
          await dispatch(updateAttachments([{ ...attachment, Name: formData.name }])).then(() =>
            enqueueSnackbar(`Renamed attachment: ${attachment.Name} -> ${formData.name}`)
          );
        }}
        onClose={() => setRenameAttachment(null)}
        onValidate={(value) => {
          const isChanged = value !== attachment.Name;
          const nameExists = items?.find((item) => item.Name === value && item.Extension === attachment.Extension);
          return isChanged && nameExists ? `There is already an attachment named ${value}.${attachment.Extension}.` : "";
        }}
      />
    );
  };

  /**
   * Create and open the delete confirmation model. On confirm dispatch an action to delete multiple attachments.
   */
  const handleDeleteAttachments = (attachments: Attachment[]) => {
    setDeleteAttachments(attachments);
  };

  const renderDeleteAttachmentsModal = (attachments: Attachment[]) => {
    return (
      <DeleteConfirmation
        data-testid="attachments-list-delete-confirmation-modal"
        onClose={() => setDeleteAttachments(null)}
        onDelete={() => {
          return new Promise<void>((resolve, reject) => {
            dispatch(deleteAttachmentsAction(attachments)).then(
              () => {
                resolve();
                setTimeout(() => setDeleteAttachments(null), 10);
              },
              (error: Error) => {
                enqueueSnackbar("Some attachments failed to delete.");
                reject(error);
              }
            );
          });
        }}
        title="Delete attachments"
        message={`Are you sure you wish to delete ${attachments.length > 1 ? "these attachments" : "this attachment"}?`}
      />
    );
  };

  /**
   * The data grid configuration.
   */

  /**
   * Set the 'selectedItems' state variable from the IDs of the currently selected row items.
   */
  const handleOnSelection = (event: SelectionChangedEvent) => {
    const selected: Attachment[] = [];
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        selected.push(node.data);
      }
    });
    setSelectedItems(selected);
  };

  /**
   * Callback function called when the datagrids data changes.
   * For example a file has been renamed. We need to reselect the
   * the previously selected rows (selection is lost when data change).
   */
  const handleOnRowDataUpdated = (event: RowDataUpdatedEvent) => {
    event.api.forEachNode((node: IRowNode, index: number) => {
      const selectNode = selectedItems.some((row) => {
        return row.ID === node.data.ID;
      });

      if (selectNode) {
        node.setSelected(true, false);
      }
    });
  };

  const deleteAttachmentAction = attachmentEntityType === "Project" ? Actions.PROJECT_DELETE_ATTACHMENT : Actions.FEATURE_DELETE_ATTACHMENT;
  const editAttachmentAction = attachmentEntityType === "Project" ? Actions.PROJECT_EDIT_ATTACHMENT : Actions.FEATURE_EDIT_ATTACHMENT;
  const uploadAttachmentAction = attachmentEntityType === "Project" ? Actions.PROJECT_UPLOAD_ATTACHMENT : Actions.FEATURE_UPLOAD_ATTACHMENT;

  const noRowsOverlayComponent = useCallback(
    () => (
      <NonIdealState
        data-testid="attachment-list-empty-state-component"
        icon={<PlaylistAdd />}
        title={`This ${attachmentEntityType} Has No Attachments Yet.`}
        description={permissionCheck(project.Permission.Level, uploadAttachmentAction) ? "Upload a file to get started." : ""}
      />
    ),
    [attachmentEntityType, project.Permission.Level, uploadAttachmentAction]
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        field: "MimeType",
        headerName: "Type",
        minWidth: 80,
        width: 90,
        suppressHeaderMenuButton: true,
        cellRenderer: MimeTypeCellRenderer,
        cellClass: "vertically-align",
        resizable: false,
      },
      {
        field: "Name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        sort: "asc",
        suppressHeaderMenuButton: true,
        valueGetter: (params: ValueGetterParams) => `${params.data.Name}.${params.data.Extension}`,
        cellRenderer: LinkCellRenderer,
        cellRendererParams: {
          onClick: (attachment: Attachment) => () => {
            handleDownloadAttachments([attachment]);
          },
        },
        comparator: stringComparator,
      },
      {
        field: "Size",
        headerName: "Size",
        valueFormatter: fileSizeValueFormatter,
        width: 80,
        type: "rightAligned",
        suppressHeaderMenuButton: true,
      },
      {
        field: "UploadedAt",
        headerName: "Uploaded At",
        valueFormatter: dateValueFormatter,
        width: 150,
        suppressHeaderMenuButton: true,
      },
      {
        field: "UploadedBy",
        headerName: "Uploaded By",
        valueFormatter: userValueFormatter(currentUsername),
        suppressHeaderMenuButton: true,
      },
    ],
    [currentUsername, handleDownloadAttachments]
  );

  const gridOptions: GridOptions = useMemo(
    () => ({
      animateRows: true,
      noRowsOverlayComponent,
      paginationAutoPageSize: true,
      pagination: true,
      rowGroupPanelShow: "never",
      rowSelection: {
        mode: "multiRow",
        checkboxes: true,
      },
      sideBar: false,
      suppressMovableColumns: true,
    }),
    [noRowsOverlayComponent]
  );

  return (
    <Box data-testid="attachment-modal-list" className={classes.container}>
      {renameAttachment != null && renderRenameAttachmentModal(renameAttachment)}
      {deleteAttachments != null && renderDeleteAttachmentsModal(deleteAttachments)}
      <Box sx={{ pl: 2, pr: 1 }}>
        <DataGridToolbar
          selected={selectedItems?.length}
          title={
            items?.length === 0
              ? `This is where you can upload and share ${attachmentEntityType} attachments.`
              : "Select one or more files to download or delete."
          }
          actions={
            <AttachmentListToolbarActions
              data-testid="attachment-list-datagrid-toolbar-actions"
              selectedItems={selectedItems}
              onOpen={handleOpenAttachment}
              onDownload={handleDownloadAttachments}
              onEdit={permissionCheck(project.Permission.Level, editAttachmentAction) ? handleRenameAttachment : undefined}
              onDelete={permissionCheck(project.Permission.Level, deleteAttachmentAction) ? handleDeleteAttachments : undefined}
              attachmentEntityType={attachmentEntityType}
            />
          }
          size="small"
        />
      </Box>
      <Box flexGrow={1}>
        <AgGrid
          columnDefs={columns}
          rowData={items}
          gridOptions={gridOptions}
          onSelectionChanged={handleOnSelection}
          onRowDataUpdated={handleOnRowDataUpdated}
          variant="outlined"
        />
      </Box>
    </Box>
  );
};

AttachmentList.displayName = "AttachmentList";
export default withStyles(customStyles)(AttachmentList);
