import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { Box } from "@mui/material";

import { selectDraftConfigEntities, selectSublayerOtherSiblingByParentId } from "fond/api";
import { MapContext } from "fond/map/MapProvider";
import { getCurrentProject } from "fond/project";
import { FlatStyleList, GroupEditor, LayerEditor, ListHeader, SortableList, StyleEditor, SublayerEditor } from "fond/styleEditor";
import { MapboxStyleLayer, Store } from "fond/types";
import { FilterConfiguration, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { projectUsesVectorTiles } from "fond/utils/project";

import { Container, Panel, Popout } from "./editor.styles";

const Editor: React.FC = () => {
  const { map } = useContext(MapContext);
  const filter = useSelector((state: Store) => state.styles.settings.filter);
  const usesVectorTiles = useSelector((state: Store) => projectUsesVectorTiles(getCurrentProject(state.project)));
  const draftEntities = useSelector((state: Store) => selectDraftConfigEntities(state));
  const selectedId = useSelector((state: Store) => state.styles.settings.selectedId);
  const selectedEntity = useMemo(() => draftEntities[selectedId], [draftEntities, selectedId]);
  const previousSelectedEntity = usePrevious(selectedEntity);
  const selectEntityById = useCallback((id: string) => draftEntities[id], [draftEntities]);
  const otherSublayer = useSelector(
    (state: Store) => selectedEntity?.Type === "SUBLAYER" && selectSublayerOtherSiblingByParentId(state, selectedEntity?.ParentID)
  );
  const previousOtherSublayer = useSelector((state: Store) =>
    previousSelectedEntity?.Type === "SUBLAYER" ? selectSublayerOtherSiblingByParentId(state, previousSelectedEntity.ParentID) : undefined
  );

  /**
   * Updates the current map with the new styles
   */
  const updateStyles = useCallback(
    ({ sublayerFilterMapbox, style }: { sublayerFilterMapbox?: FilterConfiguration["Mapbox"]; style: LayerStyle }) => {
      const currentStyle = map?.getStyle();
      if (currentStyle) {
        const index = currentStyle.layers?.findIndex((layer) => layer.id === style?.ID);
        if (index !== undefined && index >= -1) {
          const current = currentStyle.layers?.[index] as MapboxStyleLayer | undefined;
          if (current) {
            const styleFilter = sublayerFilterMapbox || current.filter;
            const update = {
              source: current.source,
              ...(styleFilter?.length ? { filter: styleFilter } : {}),
              ...style?.MapboxStyle,
              id: current.id,
            } as MapboxStyleLayer;

            // We need to maintain the current maps visibility setting so that
            // if the user is editing a currently invisibile layer it does not
            // add it back to the map
            if (current.layout?.visibility) {
              update.layout = {
                ...update.layout,
                visibility: current.layout.visibility,
              };
            }

            // We can only supply the source-layer value for vector tile projects
            if (usesVectorTiles) {
              update["source-layer"] = current["source-layer"];
            }

            currentStyle.layers?.splice(index, 1, update);
            map?.setStyle(currentStyle, { diff: true });
          }
        }
      }
    },
    [map, usesVectorTiles]
  );

  const updateSublayerStyles = useCallback(
    (sublayer: SublayerConfig) => {
      sublayer.Styles.forEach((styleId: string) => {
        const style = selectEntityById(styleId) as LayerStyle;
        updateStyles({ sublayerFilterMapbox: sublayer.FilterConfiguration?.Mapbox || [], style });
      });
    },
    [selectEntityById, updateStyles]
  );

  /**
   * If the selected styles mapbox value changes we need to update
   * the map.  This can occur when the user changes values within the form
   * or when reverting a style to its original value.
   */
  useEffect(() => {
    if (selectedEntity && selectedEntity.Type === "STYLE") {
      updateStyles({ style: selectedEntity });
    }
  }, [updateStyles, selectedEntity]);

  useEffect(() => {
    if (selectedEntity && selectedEntity.Type === "SUBLAYER") {
      selectedEntity.Styles.forEach((styleId: string) => {
        const style = selectEntityById(styleId) as LayerStyle;
        updateStyles({ sublayerFilterMapbox: selectedEntity?.FilterConfiguration?.Mapbox || [], style });
      });

      // If a sibling sublayer is using dynamic filtering we need to update styles within
      // that sublayer to flect the new FilterConfiguration.
      if (otherSublayer && selectedEntity.ID !== otherSublayer?.ID) {
        updateSublayerStyles(otherSublayer);
      }
    } else if (!selectedEntity && previousOtherSublayer && previousSelectedEntity?.Type === "SUBLAYER") {
      // Monitor for the deletion of a sublayer. If the parent layer has a
      // sublayer using the dynamic filter we need to update styles within that sublayer
      // to reflect the new FilterConfiguration.
      updateSublayerStyles(previousOtherSublayer);
    }
  }, [updateStyles, selectedEntity, selectEntityById, otherSublayer, previousOtherSublayer, previousSelectedEntity, updateSublayerStyles]);

  return (
    <Container>
      <Panel>
        <ListHeader />
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            flexGrow: 1,
            scrollPaddingTop: 32,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          className="customScrollbars"
        >
          {filter !== "" ? <FlatStyleList /> : <SortableList />}
        </Box>
      </Panel>

      {selectedEntity && selectedEntity.Type === "STYLE" && (
        <Popout data-testid="popup-style">
          <StyleEditor style={selectedEntity} key={selectedEntity.ID} />
        </Popout>
      )}

      {selectedEntity && selectedEntity.Type === "LAYER" && (
        <Popout data-testid="popup-layerconfig">
          <LayerEditor layerConfig={selectedEntity} />
        </Popout>
      )}

      {selectedEntity && selectedEntity.Type === "SUBLAYER" && (
        <Popout data-testid="popup-sublayerconfig">
          <SublayerEditor sublayerConfig={selectedEntity} key={selectedEntity.ID} />
        </Popout>
      )}

      {selectedEntity && selectedEntity.Type === "GROUP" && (
        <Popout data-testid="popup-groupconfig">
          <GroupEditor groupConfig={selectedEntity} />
        </Popout>
      )}
    </Container>
  );
};

export default Editor;
