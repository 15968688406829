import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMount, useUnmount } from "react-use";
import MapboxDraw, { DrawCreateEvent, DrawDeleteEvent, DrawModeChangeEvent, DrawUncombineEvent, DrawUpdateEvent } from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import { featureCollection } from "@turf/helpers";
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon, Polygon } from "geojson";
import { defer, isEmpty } from "lodash";
import { FeatureIdentifier } from "mapbox-gl";
import { passing_draw_polygon as passingDrawPolygon } from "mapbox-gl-draw-passing-mode";
import { SnapPolygonMode } from "mapbox-gl-draw-snap-mode";

import defaultStyles from "fond/draw/defaultStyles";
import { HistoryContext, useHistoryContext } from "fond/history";
import { MapContext } from "fond/map/MapProvider";
import { defaultToolbarConfig, ToolbarConfig } from "fond/map/Toolbar";
import { MapboxStyleLayer } from "fond/types";
import { isAnyPolygon } from "fond/types/geojson";

import directSelectMode from "./modes/base/directSelect";
import drawPoint from "./modes/base/draw_point";
import polygonCutMode from "./modes/base/polygonCut";
import polygonSplitMode from "./modes/base/polygonSplit";
import simpleSelectMode from "./modes/base/simpleSelect";
import { clip, snappingOptions, updateFeatureCollection, validateFeatures } from "./helper";

interface DrawHandlerProps {
  modes?: {
    [modeKey: string]: MapboxDraw.DrawCustomMode<any, any>;
  };
  /**
   * The configuration of toolbar actions presented to the user.
   * @default defaultToolbarConfig
   */
  config?: ToolbarConfig;
  /**
   * Flag indicating that overlapping polygon features will be automatically clipped,
   * preventing any overlapping.
   * @default false
   */
  autoClip?: boolean;
  /**
   * The initial features that should be loaded into the drawing tool.
   */
  initialFeatures: Feature[] | null;
  /**
   * The mapbox-gl source identifier that will be used to hide any existing features on the map.
   */
  source?: FeatureIdentifier["source"];
  /**
   * The mapbox-gl sourceLayer identifier that will be used to hide any existing features on the map.
   */
  sourceLayer?: FeatureIdentifier["sourceLayer"];
  /**
   * Optional clipping function that can be provided that will adjust any newly created or modified feature.
   * Only used when autoClip is set to True
   *
   * @default clip() will clip around all existing (multi-)polygons drawn features
   */
  clipFeature?(feature: Feature<Polygon | MultiPolygon>, all: Feature[]): Feature<Polygon | MultiPolygon>;
  /**
   * Callback function that used within the onCreate feature function to provide feature properties.
   */
  getFeatureProperties?(feature: Feature): GeoJsonProperties;
  /**
   * Callback function that overrides the default onCreate function.
   * Fired when a feature is created.
   */
  onCreate?(event: DrawCreateEvent): void;
  /**
   * Callback function that overrides the default onDelete function.
   * Fired when one or more features are deleted.
   */
  onDelete?(event: DrawDeleteEvent): void;
  /**
   * Callback function that overrides the default onUpdate function.
   * Fired when one or more features are updated.
   */
  onUpdate?(event: DrawUpdateEvent): void;
  /**
   * Callback function that overrides the default onUncombine function.
   * Fired when features are uncombined.
   */
  onUncombine?(event: DrawUncombineEvent): void;
  /**
   * The default starting mode to set on load.
   * @default "simple_select"
   */
  startingMode?: "simple_select" | "draw_polygon" | "draw_point" | "static" | "passing_draw_polygon";
  /**
   * Mapbox-gl-draw styles to be added to the map.
   */
  styles?: MapboxStyleLayer[];
}

const DrawHandler: React.FC<DrawHandlerProps> = ({
  config = defaultToolbarConfig,
  autoClip = false,
  modes,
  startingMode = "simple_select",
  initialFeatures,
  source,
  sourceLayer,
  styles,
  clipFeature = clip,
  getFeatureProperties,
  onCreate,
  onDelete,
  onUpdate,
  onUncombine,
}: DrawHandlerProps) => {
  const { set: setHistory, state: historyState, clear: clearHistory } = useHistoryContext<FeatureCollection>(HistoryContext);
  const { map, drawControl, setDrawMode, toolbar } = useContext(MapContext);
  const previousDrawControl = useRef(drawControl.current);
  const [init, setInit] = useState(false);

  // Store the draw control config
  useMount(() => {
    previousDrawControl.current = drawControl.current;
  });
  // restore the previous draw control for controls outside of this handler.
  useUnmount(() => {
    if (previousDrawControl.current) {
      drawControl.current = previousDrawControl.current;
    }
  });

  useEffect(() => {
    drawControl.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      userProperties: true,
      modes: modes || {
        ...MapboxDraw.modes,
        draw_polygon: SnapPolygonMode,
        draw_point: drawPoint,
        direct_select: directSelectMode,
        simple_select: simpleSelectMode,
        passing_draw_polygon: passingDrawPolygon,
        ...polygonCutMode,
        ...polygonSplitMode,
        static: StaticMode,
      },
      ...snappingOptions,
      styles: styles || defaultStyles,
    });

    toolbar.current = config;
  }, [config, map, drawControl, toolbar, styles]);

  /**
   * Called when the history state needs updated
   */
  const updateHistory = useCallback(() => {
    setHistory(drawControl.current.getAll());
  }, [drawControl, setHistory]);

  /**
   * Updates the existing boundaries feature-state, allowing for
   * styles to change based on edit status.
   */
  const setExistingFeatureVisibility = useCallback(
    (visibile: boolean) => {
      if (source) {
        initialFeatures?.forEach(({ id }) => {
          map?.setFeatureState({ id: id, source: source, sourceLayer: sourceLayer }, { isEditing: !visibile });
        });
      }
    },
    [initialFeatures, source, sourceLayer, map]
  );

  /**
   * When a feature is drawn we make sure that it does not intersect with any other existing
   * boundaries.
   */
  const handleOnCreate = useCallback(
    (event: DrawCreateEvent) => {
      const newFeatures = event.features
        .map((feature) => ({ ...feature, properties: getFeatureProperties?.(feature) || {} }) as Feature<Geometry, GeoJsonProperties>)
        .map((feature) => {
          if (isAnyPolygon(feature) && feature.id && autoClip) {
            return clipFeature(feature, drawControl.current.getAll().features);
          }
          return feature;
        });

      // Validate that no polygons have entered an invalid state
      const isValid = !autoClip || validateFeatures(newFeatures, drawControl.current.getAll().features);

      if (isValid) drawControl.current.set(updateFeatureCollection(drawControl.current.getAll(), newFeatures));

      if (!isValid) {
        drawControl.current.trash();
      } else {
        updateHistory();
      }
    },
    [autoClip, drawControl, getFeatureProperties, clipFeature, updateHistory]
  );

  /**
   * Callback fired when mapbox draw registers a feature change
   */
  const handleOnUpdate = useCallback(
    (event: DrawUpdateEvent) => {
      if (event.action === "drag_complete" || event.action === "move") {
        const currentMode = drawControl.current.getMode();
        const currentSelectionIds = drawControl.current.getSelectedIds();
        const newFeatures = event.features.map((feature) => {
          if (isAnyPolygon(feature) && feature.id && autoClip) {
            return clipFeature(feature, drawControl.current.getAll().features);
          }
          return feature;
        });

        // Validate that no polygons have entered an invalid state
        const isValid = !autoClip || validateFeatures(newFeatures, drawControl.current.getAll().features);

        if (!isValid) {
          // At least one of the features being updated is considered invalid - revert all changes.
          drawControl.current.set(isEmpty(historyState) ? featureCollection(initialFeatures || []) : historyState);
        } else {
          drawControl.current.set(updateFeatureCollection(drawControl.current.getAll(), newFeatures));

          // Since we have potentially modified the polygons we need to exit & re-enter
          // direct_select mode, otherwise mapbox-gl-draw will throw errors related to dragging vertices
          if (currentMode === "direct_select") {
            drawControl.current.changeMode("simple_select");
            drawControl.current.changeMode("direct_select", { featureId: currentSelectionIds?.[0] });
          }

          updateHistory();
        }
      } else if (event.action === "cut_features_completed") {
        updateHistory();
      }
    },
    [drawControl, autoClip, clipFeature, historyState, initialFeatures, updateHistory]
  );

  /**
   * When the maps style data changes (e.g. user changes to mono view)
   */
  const handleOnStyleChange = useCallback(() => {
    setExistingFeatureVisibility(false);
  }, [setExistingFeatureVisibility]);

  /**
   * Handle the initialization of drawing
   */
  useEffect(() => {
    // Always start in simple_select mode to initialize the drawing tools.
    setDrawMode("simple_select");

    if (initialFeatures) {
      drawControl.current.set(featureCollection(initialFeatures));
    }

    setInit(true);
    setExistingFeatureVisibility(false);
  }, [drawControl, initialFeatures, map, setDrawMode, setExistingFeatureVisibility, startingMode]);

  useEffect(() => {
    if (init) {
      // Once the draw control has been initialized & added to the map controls we
      // switch to the starting mode defined.
      defer(() => {
        setDrawMode(startingMode);
        map?.fire("draw.modechange", { type: "draw.modechange", mode: startingMode } as DrawModeChangeEvent);
      });
    }
  }, [init, map, setDrawMode, startingMode]);

  /**
   * Sync the drawing features with the current state of history as the user
   * clicks undo/redo.
   */
  useEffect(() => {
    if (map && drawControl.current && map.hasControl(drawControl.current)) {
      if (isEmpty(historyState)) {
        drawControl.current.set(featureCollection(initialFeatures || []));
      } else {
        drawControl.current.set(historyState);
      }
    }
  }, [initialFeatures, map, drawControl, historyState]);

  useEffect(() => {
    // Hide the existing boundary that we are about to edit
    setExistingFeatureVisibility(false);

    // Clean up when exiting drawing mode
    return () => {
      setExistingFeatureVisibility(true);
    };
  }, [drawControl, setExistingFeatureVisibility]);

  useEffect(() => {
    return () => {
      clearHistory();
      setDrawMode("no_feature");
    };
  }, [clearHistory, setDrawMode]);

  useEffect(() => {
    map?.on("draw.create", onCreate || handleOnCreate);
    map?.on("draw.update", onUpdate || handleOnUpdate);
    map?.on("styledata", handleOnStyleChange);
    map?.on("draw.combine", updateHistory);
    map?.on("draw.uncombine", onUncombine || updateHistory);
    map?.on("draw.delete", onDelete || updateHistory);
    map?.on("draw.union", updateHistory);

    return () => {
      map?.off("draw.create", onCreate || handleOnCreate);
      map?.off("draw.update", onUpdate || handleOnUpdate);
      map?.off("styledata", handleOnStyleChange);
      map?.off("draw.combine", updateHistory);
      map?.off("draw.uncombine", onUncombine || updateHistory);
      map?.off("draw.delete", onDelete || updateHistory);
      map?.off("draw.union", updateHistory);
    };
  }, [updateHistory, handleOnCreate, handleOnStyleChange, handleOnUpdate, map, onCreate, onUpdate, setDrawMode, onUncombine, onDelete]);

  return null;
};

export default DrawHandler;
