import { Widget } from "fond/types";

import * as cityDesignWidget from "./cityPlanner/Design";
import * as cityMapWidget from "./cityPlanner/Map";
import * as cityPropertiesWidget from "./cityPlanner/Properties";
import * as subareaListWidget from "./cityPlanner/SubareaList";
import * as commentWidget from "./Comments";
import * as designWidgets from "./Design";
import * as errorWidget from "./ErrorLog";
import { ITabProps } from "./factory";
import * as featuresListWidget from "./FeaturesList";
import * as legendWidget from "./Legend";
import * as mapWidgets from "./Map";
import * as propertiesWidget from "./Properties";
import * as mapSubLayoutWidget from "./SubLayout";
import * as versionsWidget from "./Versions";

// Define a Map collection of Widgets
const Components = new Map<Widget, { Widget: React.FC<ITabProps>; Title?: React.FC<ITabProps>; Icon: React.FC<ITabProps> }>();

Components.set(Widget.CityDesign, cityDesignWidget);
Components.set(Widget.CityMap, cityMapWidget);
Components.set(Widget.CityProperties, cityPropertiesWidget);
Components.set(Widget.Comments, commentWidget);
Components.set(Widget.DesignPanel, designWidgets);
Components.set(Widget.ErrorLog, errorWidget);
Components.set(Widget.FeaturesList, featuresListWidget);
Components.set(Widget.Legend, legendWidget);
Components.set(Widget.Map, mapWidgets);
Components.set(Widget.MapLayout, mapSubLayoutWidget);
Components.set(Widget.Versions, versionsWidget);
Components.set(Widget.Properties, propertiesWidget);
Components.set(Widget.SubareaList, subareaListWidget);

export { Components };
export * from "./helper";
