import React, { useMemo } from "react";

import { useFilterLayers } from "fond/hooks/useFilterLayers";

import { LayerFilterContext } from "./LayerFilterContext";

interface LayerFilterProviderProps {
  defaultValues?: Record<string, string[]>;
  versionId: string;
  children: React.ReactNode;
}

const LayerFilterProvider: React.FC<LayerFilterProviderProps> = ({
  defaultValues = {},
  versionId,
  children,
}: LayerFilterProviderProps): React.ReactNode => {
  const { filters, onChange } = useFilterLayers(versionId, defaultValues);

  const value = useMemo(
    () => ({
      filters,
      onChange,
    }),
    [filters, onChange]
  );

  return <LayerFilterContext.Provider value={value}>{children}</LayerFilterContext.Provider>;
};

export default LayerFilterProvider;
