import { MultiProject, Project, projectTypeLabelMapping } from "fond/types";

/**
 * At the moment only collaboration projects use vector tiles.
 * @returns {boolean} flag indicating if the project uses vector tiles to display map data.
 */
export const projectUsesVectorTiles = (project: Project | MultiProject): boolean => {
  return isCollaborationProject(project);
};

/**
 * Determines if the current project supports styles to be customised via the style editor.
 * Currently we only support collaboration projects, but that may change in the future.
 *
 * @param project The project or multiproject entity
 * @returns {boolean} flag indicating if styles can be customised.
 */
export const canCustomiseStyles = (project: Project | MultiProject): boolean => {
  return project.EntityType === "project" && project.SubType === "collaboration";
};

export const isPlannerProject = (project?: Project | MultiProject): project is Project => {
  return !!project && project.EntityType === "project" && project.SubType === "planner";
};

export const isDesignProject = (project?: Project | MultiProject): project is Project => {
  return !!project && project.EntityType === "project" && project.SubType === "design";
};

export const isCollaborationProject = (project?: Project | MultiProject): project is Project => {
  return !!project && project.EntityType === "project" && !!project.HasCustomLayerConfig;
};

export const isMultiProject = (project?: Project | MultiProject): project is MultiProject => {
  return !!project && project.EntityType === "multi_project";
};

/**
 * Returns the name of an entity (required due to inconsistent naming conventions)
 * @param project The project or multiproject entity
 * @returns {string} The entities name
 */
export const getProjectName = (project?: Project | MultiProject): string => {
  if (!project) return "";

  // FIXME: API should return Name instead of ProjectName
  return isMultiProject(project) ? project.Name : project.ProjectName;
};

export const getProjectTypeLabel = (project: Project | MultiProject): string => {
  if (!project) return "";

  if (isMultiProject(project)) return "City Planner";
  return projectTypeLabelMapping[project.SubType];
};
