import { ConfigurationUpsert, MultiProject } from "fond/types";
import { FilterConfiguration, LayerStyle } from "fond/types/ProjectLayerConfig";

import { FamilialLayerConfig, groupConfigTemplate, layerConfigTemplate } from "./configuration";

export enum BoundaryLayerId {
  ROOT = "multiProjectBoundaryGroup",
  LAYER = "multiProjectBoundaryLayer",
}

const key = "subareas";

/**
 * Create the Subarea configuration.
 */
export const boundaryConfiguration = (multiProject: MultiProject): ConfigurationUpsert => {
  const { config, descendants } = createBoundaryLayer(multiProject);
  const boundaryGroup = groupConfigTemplate(BoundaryLayerId.ROOT, [config], "Boundary", true);
  return [...descendants, config, boundaryGroup];
};

const createBoundaryLayer = (multiProject: MultiProject): FamilialLayerConfig => {
  const filter: FilterConfiguration = { Mapbox: ["==", ["get", "boundaryId"], multiProject.ID], Type: "expression" };
  const descendants = layerStyles(BoundaryLayerId.LAYER, "grey", filter, 0.1);
  const config = layerConfigTemplate(BoundaryLayerId.LAYER, key, "Input area", descendants, [], BoundaryLayerId.ROOT);

  return { config, descendants };
};

export const layerStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity !== undefined ? opacity : 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
  {
    ID: `${layerId}-polygon-stroke`,
    Name: `${layerId}-polygon-stroke`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "line-width": 1,
        "line-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 1],
        "line-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", "black"],
        "line-dasharray": [2, 2],
      },
    },
    RawStyles: {
      Type: "line",
      LineOpacity: 1,
      LineColor: "black",
      LineWidth: 2,
    },
    Type: "STYLE",
  },
];
