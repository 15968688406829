import { Box } from "@mui/material";

import { TabPanel } from "./ReportConfigurationTabPanel.styles";

type ReportConfigurationTabPanelProps = {
  children: React.ReactNode;
  value: number;
  id: number;
};

export const ReportConfigurationTabPanel: React.FC<ReportConfigurationTabPanelProps> = (props: ReportConfigurationTabPanelProps) => {
  const { children, value, id } = props;

  return (
    <TabPanel
      className="scroll-shadow customScrollbars greyBackground"
      role="tabpanel"
      hidden={value !== id}
      id={`report-config-tabpanel-${id}`}
      aria-labelledby={`report-config-tab-${id}`}
    >
      <Box sx={{ py: 3 }}>{children}</Box>
    </TabPanel>
  );
};
