import React from "react";
import { useSelector } from "react-redux";
import { ListItemIcon, MenuItem } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { getCurrentProject } from "fond/project";
import { openImportModal } from "fond/project/redux";
import { Project, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";

import { StyledImportIcon } from "./ProjectMenuItem.styles";

interface ImportMenuItemProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Callback on modal close request.
   */
  handleClose: () => void;
}

/**
 * The ImportMenuItem may be used by permitted users to open the import modal from the project menu/
 */
const ImportMenuItem: React.FC<ImportMenuItemProps> = ({ "data-testid": dataTestid = "import-menu-item", handleClose }: ImportMenuItemProps) => {
  const dispatch = useAppDispatch();
  const project = useSelector((state: Store) => getCurrentProject(state.project));

  const canImport = usePermissionCheck(Actions.PROJECT_IMPORT, project.Permission.Level);

  // The modal is controlled though the project.modal state, in a way similar to the Architecture modal.
  const handleClick = () => {
    if (canImport) {
      dispatch(openImportModal());
      handleClose();
    }
  };

  return (
    <MenuItem data-testid={dataTestid} onClick={handleClick} disabled={!canImport}>
      <ListItemIcon>
        <StyledImportIcon />
      </ListItemIcon>
      Import
    </MenuItem>
  );
};

export default ImportMenuItem;
