import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AgGridReact } from "@ag-grid-community/react";
import { Folder } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Divider, Typography } from "@mui/material";

import { LoadingButton } from "ui";

import { useCarveMultiProjectMutation, useGetMultiProjectQuery } from "fond/api";
import { CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN } from "fond/constants";
import { MultiProject, MultiProjectArea } from "fond/types";

import { SubareaList } from "../SubareaList";

import { ViewButton } from "./CarvePanel.styles";

interface CarvePanelProps {
  multiProject: MultiProject;
}

const CarvePanel: React.FC<CarvePanelProps> = ({ multiProject }: CarvePanelProps) => {
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact<MultiProjectArea>>(null);
  const { isLoading, isFetching } = useGetMultiProjectQuery(multiProject.ID);
  const [carveMultiProject, { isLoading: isCarving, isError }] = useCarveMultiProjectMutation();
  const areasWithProjects = useMemo(() => multiProject?.Areas.filter((area) => area.Project?.ID).map(({ ID }) => ID), [multiProject]);
  const [selected, setSelected] = useState<string[]>([]);
  const hasExceededMaxSubareaCount = selected.length > CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN;
  const allProjectsCreated = multiProject?.Areas.length === areasWithProjects.length;

  const handleOnClick = async () => {
    if (multiProject && selected.length > 0) {
      try {
        await carveMultiProject({ ID: multiProject.ID, Areas: selected.map((ID) => ({ ID })) });
        setSelected([]);
      } catch (error) {
        throw new Error("Failed to create projects");
      }
    }
  };

  const handleOnViewButtonClick = () => {
    navigate(`/cities/${multiProject?.Folder.ID}`);
  };

  const handleOnSelectionChanged = useCallback(() => {
    if (gridRef.current?.api) {
      const rowIds = gridRef.current?.api.getSelectedRows().map(({ ID }) => ID);
      setSelected(rowIds);
    }
  }, []);

  const loading = isLoading || isFetching || isCarving;

  return (
    <>
      {areasWithProjects.length === 0 && <Typography variant="subtitle2">No plan generated</Typography>}

      {areasWithProjects.length > 0 && (
        <>
          <Typography variant="subtitle2">Projects created</Typography>
          <Typography variant="caption">{areasWithProjects.length} subarea projects have been created</Typography>
          <Divider sx={{ mt: 2 }} />
          <ViewButton startIcon={<Folder color="primary" />} onClick={handleOnViewButtonClick} fullWidth>
            View city planner projects
          </ViewButton>
          <Divider />
        </>
      )}

      {!allProjectsCreated && (
        <>
          {areasWithProjects.length > 0 && (
            <Typography variant="subtitle2" sx={{ mt: 3 }}>
              Generate additional projects
            </Typography>
          )}

          <Box sx={{ mb: 2 }}>
            <Typography variant="caption">Confirm the subareas you want to include in your city plan.</Typography>
          </Box>
          <SubareaList
            multiProject={multiProject}
            onSelectionChanged={handleOnSelectionChanged}
            ref={gridRef}
            previouslySelectedIds={areasWithProjects}
            selectedIds={selected}
            loading={loading}
          />
        </>
      )}

      {isError && (
        <Alert sx={{ mt: 1 }} severity="error" variant="compact">
          <AlertTitle sx={{ fontSize: 14, mb: 0 }}>Error creating subarea projects</AlertTitle>
          Something went wrong with creating the subarea projects. Please try again.
        </Alert>
      )}

      {hasExceededMaxSubareaCount && (
        <Alert sx={{ mt: 1 }} severity="error" variant="compact">
          <AlertTitle sx={{ mb: 0 }}>Too many subareas</AlertTitle>
          Projects can be created for up to {CITY_PLANNER_MAX_SUBAREA_COUNT_FOR_DESIGN} subareas but {selected.length} are currently selected. Please
          reduce the number of subareas selected and try again.
        </Alert>
      )}

      {!allProjectsCreated && (
        <Box sx={{ mt: 1 }}>
          <LoadingButton
            onClick={handleOnClick}
            fullWidth
            variant="contained"
            disabled={loading || hasExceededMaxSubareaCount || selected.length === 0}
            loading={loading}
          >
            Create Projects
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

export default CarvePanel;
