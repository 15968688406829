import React from "react";
import { useSelector } from "react-redux";
import { Box, Divider, PopoverOrigin } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectFolderById, useGetAccountModulesQuery, useGetFoldersQuery, useGetProjectQuery, useGetVersionQuery } from "fond/api";
import { useFeatureFlag } from "fond/featureFlags";
import { getCurrentProject } from "fond/project";
import { Bom, BulkAttachment, Files } from "fond/project/pageMenu/attachment";
import { KnowledgeBase, Support } from "fond/project/pageMenu/help";
import {
  Close,
  Copy,
  ExportMenuItem,
  ImportMenuItem,
  Move,
  Rename,
  Settings,
  Share as ShareMenuItem,
  StyleEditor,
} from "fond/project/pageMenu/project";
import { Layout, MapAppearance } from "fond/project/pageMenu/view";
import { Store } from "fond/types";
import { accountModuleCheck, Actions } from "fond/utils/permissions";
import { canCustomiseStyles, isCollaborationProject } from "fond/utils/project";

import { Menu, MenuButton } from "./PageMenu.styles";

interface IProps {
  anchorOrigin: PopoverOrigin;
  menuAnchorEl: HTMLElement | null;
  open: boolean;
  onMenuClick(event: React.MouseEvent<HTMLButtonElement>): void;
  onClose(): void;
}

const ProjectMenu: React.FC<IProps> = ({ anchorOrigin, menuAnchorEl, open, onClose, onMenuClick }: IProps) => {
  const projectId = useSelector((state: Store): string => getCurrentProject(state.project)?.ID);
  const { data: project } = useGetProjectQuery(projectId);
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const architecture = version?.Architecture ?? null;
  const currentFolder = useSelector((state: Store) => (project?.FolderID ? selectFolderById(state, project.FolderID) : undefined));
  const { isFetching: isFolderFetching } = useGetFoldersQuery(undefined);
  const permissionLevel = project?.Permission.Level;
  const collaborationProject = useSelector((state: Store) => isCollaborationProject(getCurrentProject(state.project)));
  const projectSupportsCustomisedStyles = useSelector((state: Store) => canCustomiseStyles(getCurrentProject(state.project)));
  const { value: attachmentsBulkImportFeatureFlagEnabled } = useFeatureFlag("attachments_bulk_import");
  const { data: accountModules } = useGetAccountModulesQuery(project?.Account.ID ?? skipToken);
  const canPerformAttachmentsBulkImport = accountModuleCheck(accountModules, Actions.ATTACHMENTS_BULK_IMPORT);

  /**
   * Definition of the menu items.
   */
  const menus = [
    {
      id: "project-menu-project",
      title: "Project",
      children: (
        <>
          <ShareMenuItem handleClose={onClose} />
          <Divider style={{ margin: 0 }} />
          {collaborationProject && <ImportMenuItem handleClose={onClose} />}
          <ExportMenuItem handleClose={onClose} />
          <Divider style={{ margin: 0 }} />
          {project && <Copy project={project} handleClose={onClose} />}
          {project && permissionLevel && <Rename project={project} permissionLevel={permissionLevel} handleClose={onClose} />}
          {project && <Move project={project} isFetching={isFolderFetching} handleClose={onClose} />}
          <Divider style={{ margin: 0 }} />
          {permissionLevel && <Settings permissionLevel={permissionLevel} handleClose={onClose} />}
          <Divider style={{ margin: 0 }} />
          {permissionLevel && projectSupportsCustomisedStyles && (
            <>
              <StyleEditor permissionLevel={permissionLevel} handleClose={onClose} />
              <Divider style={{ margin: 0 }} />
            </>
          )}
          <Close currentFolderId={currentFolder?.ID} />
        </>
      ),
    },
    {
      id: "project-menu-view",
      title: "View",
      children: (
        <>
          <MapAppearance parentMenuOpen={Boolean(menuAnchorEl?.id === "project-menu-view")} handleClose={onClose} />
          <Divider style={{ margin: 0 }} />
          <Layout type="project" parentMenuOpen={Boolean(menuAnchorEl?.id === "project-menu-view")} handleClose={onClose} />
        </>
      ),
    },
    {
      id: "project-menu-attachment",
      title: "Attachment",
      children: (
        <>
          {project && <Bom project={project} architecture={architecture} handleClose={onClose} />}
          {permissionLevel && <Files permissionLevel={permissionLevel} handleClose={onClose} />}
          {attachmentsBulkImportFeatureFlagEnabled && canPerformAttachmentsBulkImport && permissionLevel && (
            <BulkAttachment permissionLevel={permissionLevel} handleClose={onClose} />
          )}
        </>
      ),
    },
    {
      id: "project-menu-help",
      title: "Help",
      children: (
        <>
          <Support handleClose={onClose} />
          <KnowledgeBase handleClose={onClose} />
        </>
      ),
    },
  ];

  return (
    <>
      {menus.map((menuItem) => (
        <Box key={menuItem.id}>
          <MenuButton
            id={menuItem.id}
            aria-controls={menuItem.id}
            aria-haspopup="true"
            data-testid={`${menuItem.id}-button`}
            onClick={onMenuClick}
            onMouseEnter={open ? onMenuClick : undefined}
            size="small"
          >
            {menuItem.title}
          </MenuButton>
          <Menu
            data-testid={menuItem.id}
            open={Boolean(menuAnchorEl?.id === menuItem.id)}
            anchorEl={menuAnchorEl}
            keepMounted
            anchorOrigin={anchorOrigin}
            MenuListProps={{ disablePadding: true }}
            disablePortal
          >
            <div key={menuItem.id}>{menuItem.children}</div>
          </Menu>
        </Box>
      ))}
    </>
  );
};

export default ProjectMenu;
