import React, { useCallback } from "react";
import { Check, FilterList } from "@mui/icons-material";
import { Box, Checkbox, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";

import { ReturnType } from "fond/hooks/useFilterLayers";

import { MenuItem, Select } from "./LayerFilter.styles";

export type LayerFilterOption = { ID: string; Label: string; Type: "Option" } | { ID: string; Label: string; Type: "Category" };

export type LayerFilterProps = {
  id: string;
  multiple?: boolean;
  options: LayerFilterOption[];
  filteredLayerIds: string[];
} & Pick<ReturnType, "onChange">;

const LayerFilter: React.FC<LayerFilterProps> = ({ id, filteredLayerIds, onChange, options, multiple = false }: LayerFilterProps) => {
  const optionIds = options.filter(({ Type }) => Type === "Option").map(({ ID }) => ID);

  const handleOnLayerSelect = useCallback(
    (layerId: string) => (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      onChange(id, layerId, optionIds, multiple);
    },
    [onChange, id, optionIds, multiple]
  );

  const getMenuItem = (option: LayerFilterOption) => {
    if (option.Type === "Category")
      return (
        <ListSubheader key={option.ID} sx={{ fontSize: 13, lineHeight: 2, color: "grey" }}>
          {option.Label}
        </ListSubheader>
      );

    const checked = filteredLayerIds?.includes(option.ID);
    return (
      <MenuItem key={option.ID} onClick={handleOnLayerSelect(option.ID)} value={option.ID} dense>
        <ListItemIcon>
          {multiple && <Checkbox checked={checked} disableRipple />}
          {!multiple && checked && <Check />}
        </ListItemIcon>
        <ListItemText primary={option.Label} />
      </MenuItem>
    );
  };

  return (
    <Select
      displayEmpty
      size="small"
      multiple
      value={filteredLayerIds}
      renderValue={() => {
        const value = optionIds.length - (filteredLayerIds?.length || 0);
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FilterList color="primary" sx={{ fontSize: 16, mr: 0.5 }} /> {value > 0 ? `Filters (${value})` : "Filter"}
          </Box>
        );
      }}
    >
      {options.map(getMenuItem)}
    </Select>
  );
};

export default LayerFilter;
