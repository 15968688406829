import React, { useCallback, useEffect } from "react";
import { usePrevious } from "react-use";
import { uniqWith } from "lodash";
import mapboxgl, { FeatureIdentifier } from "mapbox-gl";

import { getCurrentProjectHighlights } from "fond/project";
import { selectFeature, unselectFeature } from "fond/project/redux";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";

/**
 * A hook that adds click events to the passed map to handle feature selection & highlighting.
 */
export const useFeatureHandler = (map?: mapboxgl.Map): void => {
  const dispatch = useAppDispatch();
  const highlightedFeatures: FeatureIdentifier[] | undefined = useAppSelector((state) => getCurrentProjectHighlights(state.project));
  const previousHighlights: FeatureIdentifier[] | undefined = usePrevious(highlightedFeatures || []);

  /**
   * Handles setting the features states
   * For example a featureState { isSelected: true } will hightlight the features
   * @param object The features & state value to be changed.
   */
  const setFeatureState = useCallback(
    ({ features, state }: { features: FeatureIdentifier[] | mapboxgl.MapboxGeoJSONFeature[]; state: Record<string, unknown> }) => {
      features.forEach((feature) => {
        map?.setFeatureState(
          {
            source: feature.source,
            sourceLayer: feature.sourceLayer,
            id: feature.id,
          },
          state
        );
      });
    },
    [map]
  );

  /**
   * Handles the monitoring of the highlighted features
   */
  useEffect(() => {
    // Unhighlighted the previous highlighted features
    if (previousHighlights?.length) {
      setFeatureState({ features: previousHighlights, state: { isSelected: false } });
    }

    // Highlight the newly selected features
    if (highlightedFeatures?.length) {
      setFeatureState({ features: highlightedFeatures, state: { isSelected: true } });
    }
  }, [highlightedFeatures, previousHighlights, setFeatureState]);

  /**
   * Handles selecting or deselecting a feature via redux,
   * @param feature The feature to be selected, if undefined the current selection will be deselected.
   * @param lngLat
   */
  const handleOnSelectFeature = useCallback(
    (feature: mapboxgl.MapboxGeoJSONFeature | undefined, lngLat: mapboxgl.LngLatLike) => {
      // We currently do not support the boundary feature being selected
      if (feature && feature.properties?.type !== "boundary") {
        dispatch(selectFeature(feature, lngLat));
      } else {
        dispatch(unselectFeature());
      }
    },
    [dispatch]
  );

  /**
   * Given a Mapbox mouse event, return a FOND feature (as opposed to a base
   * Mapbox feature like a landmark, which we don't let users interact with)
   * if there is one within 5 pixels of the event.
   * @param event
   */
  const getFeatures = useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      const queryBbox: [mapboxgl.PointLike, mapboxgl.PointLike] = [
        [event.point.x - 5, event.point.y - 5],
        [event.point.x + 5, event.point.y + 5],
      ];

      const features = uniqWith(
        map?.queryRenderedFeatures(queryBbox).filter((feature: mapboxgl.MapboxGeoJSONFeature) => feature.properties?.boundaryId != null),
        (valueA, valueB) => valueA.id === valueB.id
      );

      return features;
    },
    [map]
  );

  /**
   * Handles the click event for the map.
   */
  const handleOnMapClick = useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      const features = getFeatures(event);
      handleOnSelectFeature(features?.[0], event.lngLat);
    },
    [getFeatures, handleOnSelectFeature]
  );

  /**
   * Handles the mouse move event for the map.
   */
  const handleOnMouseMove = useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      if (map) {
        // eslint-disable-next-line no-param-reassign
        map.getCanvas().style.cursor = getFeatures(event).filter((feature) => feature.properties?.type !== "boundary").length > 0 ? "pointer" : "";
      }
    },
    [getFeatures, map]
  );

  useEffect(() => {
    map?.on("click", handleOnMapClick);
    map?.on("mousemove", handleOnMouseMove);
    return () => {
      map?.off("click", handleOnMapClick);
      map?.off("mousemove", handleOnMouseMove);
    };
  }, [handleOnMapClick, handleOnMouseMove, map]);
};
