import React, { useContext, useState } from "react";
import { CloudUpload } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { featureCollection } from "@turf/helpers";
import union from "@turf/union";
import { MultiPolygon, Polygon } from "geojson";

import { isAnyPolygon, isFeatureCollection } from "fond/types/geojson";
import { Dropzone, Modal } from "fond/widgets";

import { MapContext } from "../MapProvider";

import { IButtonProps } from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

const ImportGeojsonButton: React.FC<IButtonProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<{ title: string; message: string } | undefined>();
  const { map, drawControl } = useContext(MapContext);

  const handleOnClick = () => {
    // Switch back to feature select mode.
    drawControl.current.changeMode("simple_select");
    map?.fire("draw.modechange", { type: "draw.modechange", mode: "simple_select" });

    setShowModal(true);
  };

  const handleOnClose = () => {
    setShowModal(false);
    setError(undefined);
  };

  const handleOnDrop = (files: File[]) => {
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onload = function (e) {
        let contents = e.target?.result;

        if (typeof contents === "string") {
          importFeatureCollection(contents);
        }
      };
      reader.readAsText(file);
    });
  };

  const importFeatureCollection = (contents: string) => {
    setError(undefined);
    try {
      // Validate upload and convert polygon feature collection.
      const collection = featureCollection<Polygon | MultiPolygon>(JSON.parse(contents).features.filter(isAnyPolygon));
      if (isFeatureCollection(collection) && collection.features.length > 0) {
        setShowModal(false);

        // Fire the drawing event that initiates the auto carve process.
        const features = collection.features.length > 1 ? [union(collection)] : collection.features;
        map?.fire("draw.passing-create", { type: "draw.modechange", features });
      } else if (!isFeatureCollection(collection)) {
        setError({
          title: "Invalid GeoJSON file",
          message: "Please select a valid geojson file.",
        });
      } else if (collection.features.length === 0) {
        setError({
          title: "No polygons found",
          message: "The selected file does not contain any valid Multi/Polygons.",
        });
      }
    } catch (e: any) {
      setError({
        title: "Invalid GeoJSON file",
        message: "Please select a valid geojson file.",
      });
    }
  };

  return (
    <>
      <ToolbarButton title="Upload polygon" onClick={handleOnClick}>
        <CloudUpload />
      </ToolbarButton>

      {showModal && (
        <Modal
          open
          variant="default"
          header="Upload polygon"
          headerIcon={<CloudUpload />}
          content={
            <>
              {error && (
                <Alert severity="error" sx={{ my: 1 }}>
                  <AlertTitle>{error.title}</AlertTitle>
                  {error.message}
                </Alert>
              )}

              <Dropzone onDropAccepted={handleOnDrop} acceptedFiles={{ "application/unknown": [".geojson"] }} multiple={false}>
                <Box display="flex" flexDirection="column" alignItems="center" width="100%" py={4} borderRadius="4px">
                  <CloudUpload />
                  <Typography variant="caption" sx={{ color: (theme) => theme.palette.biarri.primary.darkGrey }}>
                    Drag and drop files or click to browse
                  </Typography>
                  <Typography variant="caption" sx={{ color: "rgba(101, 101, 101, 0.80)" }}>
                    GEOJSON files
                  </Typography>
                </Box>
              </Dropzone>
            </>
          }
          actions={
            <Button color="primary" onClick={handleOnClose}>
              Close
            </Button>
          }
        />
      )}
    </>
  );
};

export default ImportGeojsonButton;
