import React from "react";
import { Step } from "react-joyride";
import { Chip, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Tour } from "fond/types";
import { SupportLink } from "fond/widgets";

import BaseTour from "./GuidedTour";

interface IProps {
  onComplete(): void;
}

const useCustomStyles = makeStyles((theme: Theme) => ({
  chip: {
    fontSize: 12,
    height: 16,
    fontWeight: 500,
  },
  list: {
    fontSize: 14,
  },
}));

const LayoutTour: React.FC<IProps> = ({ onComplete }: IProps) => {
  const classes = useCustomStyles();

  const steps: Step[] = [
    {
      title: "FOND Update: Introducing the new FOND Dynamic Layout",
      target: ".flexlayout__border_left",
      content: (
        <>
          <Typography variant="body2" gutterBottom>
            We have been working hard to improve the way in which your projects are displayed within FOND. It is now time for you to take full control
            of the layout!
          </Typography>
          <Typography variant="body2">The following steps will help guide you through customization.</Typography>
        </>
      ),
      placement: "center",
      showSkipButton: false,
    },
    {
      title: "What's on the page",
      target: "[data-testid='legend']",
      content: (
        <>
          <Typography variant="body2">When viewing a project there are several key widgets that can be customised:</Typography>
          <ul className={classes.list}>
            <li>Map</li>
            <li>Legend</li>
            <li>Design Tools</li>
            <li>Comments</li>
            <li>Features List</li>
            <li>Error Log</li>
            <li>
              Versions <Chip className={classes.chip} variant="filled" color="error" size="small" label="NEW" />
            </li>
          </ul>
          <Typography variant="body2">Each widget’s location can be fully customised; simply drag & drop any tab to get started.</Typography>
        </>
      ),
      placement: "left",
    },
    {
      title: "Want more space?",
      target: "[data-layout-path='/c1/ts0/tabstrip'] .flexlayout__tab_toolbar",
      content:
        "If you are in need of more space on the screen we now have the ability for widgets to be maximized. If that is not enough, you can undock the widget into its own floating window and then move onto a second screen.",
      placement: "left",
    },
    {
      title: "Navigation has changed",
      target: "[data-testid='project-menu-bar']",
      content:
        "Where is the sidebar navigation? Well, we ran out of room due to all the new features, so we moved everything into this toolbar & added a few more options (with more to come shortly).",
      placement: "bottom",
    },
    {
      title: "Your account & Sharing",
      target: "[data-testid='top-bar-right']",
      content:
        "We have also added a quick share option to the top right of the project page, as well as the ability to view your account or log out.",
      placement: "bottom",
    },
    {
      title: "Feedback",
      target: "body",
      content: (
        <>
          <Typography variant="body2" gutterBottom>
            If you have any feedback on the new layout we would love to hear it!
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please <SupportLink text="contact us" /> with your feedback.
          </Typography>
        </>
      ),
      placement: "center",
      showSkipButton: false,
      showProgress: false,
    },
  ];

  return (
    <BaseTour
      run
      tourKey={Tour.DYNAMIC_LAYOUT}
      disableCloseOnEsc
      disableOverlayClose
      steps={steps}
      continuous
      showSkipButton
      showProgress
      onComplete={onComplete}
      onSkip={onComplete}
    />
  );
};

export default LayoutTour;
