import React, { useMemo } from "react";
import { ColDef, GridOptions, ValueFormatterParams } from "@ag-grid-community/core";
import { SelectAll } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import area from "@turf/area";

import { useGetMultiProjectQuery, useGetMultiProjectStatisticsQuery } from "fond/api";
import { convertMetersToFeet } from "fond/utils";
import { sqMetresToSqMiles, sqMilesToSqMetres } from "fond/utils/area";
import { useAppSelector } from "fond/utils/hooks";
import { formatFractionPercent, formatInteger, formatNumber, formatPercent } from "fond/utils/number";
import { AgGrid } from "fond/widgets";

interface RowData {
  id:
    | "addresses"
    | "area"
    | "attFiberCoverage"
    | "density"
    | "name"
    | "paths"
    | "score"
    | "tMobileFiberCoverage"
    | "underserved"
    | "verizonFiberCoverage";
  property: string;
  value: string | number;
}

const valueFormatter = (params: ValueFormatterParams<RowData>) => {
  if (!params.data) return null;

  if (params.value === undefined) return "Loading...";
  const { id } = params.data;
  if (id === "area") return formatNumber(sqMetresToSqMiles(params.value), 2);
  if (id === "name") return params.value;
  if (["underserved", "sdu"].includes(id)) return formatFractionPercent(params.value);
  if (id === "score") return formatNumber(params.value, 0);
  if (id === "density") return formatNumber(sqMilesToSqMetres(params.value), 2);
  if (id === "paths") return formatInteger(convertMetersToFeet(params.value));
  if (id === "addresses") return formatInteger(params.value);
  if (["attFiberCoverage", "tMobileFiberCoverage", "verizonFiberCoverage"].includes(id)) return formatPercent(params.value);

  return formatNumber(params.value, 0);
};

const Properties: React.FC = () => {
  const { selectedFeature } = useAppSelector((state) => state.project);
  const multiProjectId = useAppSelector((state) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { data } = useGetMultiProjectStatisticsQuery(multiProjectId ?? skipToken);
  const selectedFeatureData = useMemo(
    () => selectedFeature && data?.Areas.find(({ Area: { ID } }) => ID === selectedFeature.layerId),
    [data?.Areas, selectedFeature]
  );
  const selectedAreaData = useMemo(
    () => multiProject?.Areas.find(({ ID }) => ID === selectedFeature?.layerId),
    [multiProject?.Areas, selectedFeature?.layerId]
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        headerName: "Property",
        field: "property",
        flex: 1,
        resizable: false,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "",
        field: "value",
        flex: 1,
        resizable: false,
        suppressHeaderMenuButton: true,
        valueFormatter,
      },
    ],
    []
  );

  // These values need to update if the underlying areas change (e.g. playing around with scores)
  const rowData = useMemo(() => {
    if (!selectedFeature || !selectedFeatureData) return [];

    return [
      {
        id: "name",
        property: "Name",
        value: selectedAreaData?.Name,
      },
      {
        id: "score",
        property: "Score",
        value: selectedAreaData?.Score,
      },
      {
        id: "density",
        property: "Density (sq mi)",
        value: selectedFeatureData.AddressDensity.AddressesPerSquareMeters,
      },
      {
        id: "area",
        property: "Area (sq mi)",
        value: area(selectedFeature.feature),
      },
      {
        id: "sdu",
        property: "SDU proportion",
        value: selectedFeatureData.AddressBreakdown.Sdu.Proportion,
      },
      {
        id: "underserved",
        property: "Underserved proportion",
        value: selectedFeatureData.AddressUnderservedProportion.AddressUnderservedProportion,
      },
      {
        id: "addresses",
        property: "Addresses",
        value: selectedFeatureData.AddressCounts.AddressCount,
      },
      {
        id: "paths",
        property: "Paths (ft)",
        value: selectedFeatureData.StreetCounts.StreetIntersectionLengthMetres,
      },
      {
        id: "attFiberCoverage",
        property: "AT&T fiber coverage",
        value: selectedFeatureData.ProviderFiberCoverage["AT&T"].FiberCoverageProportion,
      },
      {
        id: "tMobileFiberCoverage",
        property: "T-Mobile fiber coverage",
        value: selectedFeatureData.ProviderFiberCoverage["T-Mobile"].FiberCoverageProportion,
      },
      {
        id: "verizonFiberCoverage",
        property: "Verizon fiber coverage",
        value: selectedFeatureData.ProviderFiberCoverage.Verizon.FiberCoverageProportion,
      },
    ];
  }, [selectedFeature, selectedFeatureData, selectedAreaData]);

  if (!selectedFeature) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <SelectAll color="action" sx={{ mb: 2 }} />
          <Typography variant="body3">Select a feature to view</Typography>
        </Box>
      </Box>
    );
  }

  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    domLayout: "normal",
  };

  return <AgGrid columnDefs={columns} rowData={rowData} gridOptions={gridOptions} size="compact" variant="borderless" />;
};

export default Properties;
