import { connect } from "react-redux";
import { featureFilter } from "@mapbox/mapbox-gl-style-spec";
import convert from "convert-units";
import _, { camelCase, startCase } from "lodash";
import { v4 as uuid } from "uuid";

export { pickIDs } from "./entity";

/**
 * Constants for the various response codes that can be returned by AWS Cognito.
 * Not the best place to put this but it needs to live somewhere for now so it can be shared.
 */
export const CognitoResponseCode = {
  UNKNOWN_ERROR: "UnknownError",
  NETWORK_ERROR: "NetworkError",
  INCORRECT_PASSWORD: "NotAuthorizedException",
  USER_NOT_FOUND: "UserNotFoundException",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  EXPIRED_CODE: "ExpiredCodeException",
  INCORRECT_CODE: "CodeMismatchException",
  LIMIT_EXCEEDED: "LimitExceededException",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  INVALID_PARAMETER: "InvalidParameterException",
  INVALID_PASSWORD_EXCEPTION: "InvalidPasswordException",
  USER_NOT_CONFIRMED: "UserNotConfirmedException",
};

/**
 * Return the helper text based on the cognito response code.
 * @param {string} cognito response code
 * @returns {string} helper text
 */
export function getCognitoResponseHelperText(cognitoResponseCode) {
  if (cognitoResponseCode === CognitoResponseCode.USER_NOT_FOUND) {
    return "Couldn't find an account for this email";
  }

  if (isUserNotConfirmed(cognitoResponseCode)) {
    return "This email hasn't been verified";
  }

  if (cognitoResponseCode === CognitoResponseCode.INCORRECT_PASSWORD || cognitoResponseCode === CognitoResponseCode.INVALID_PASSWORD_EXCEPTION) {
    return "Incorrect password. Try again or click Forgot password to reset.";
  }
  return "";
}

/**
 * Check the cognito response code to see if it is a unverified user error.
 * @param {string} cognito response code
 * @returns {boolean}
 */
export function isUserNotConfirmed(cognitoResponseCode) {
  return cognitoResponseCode && [CognitoResponseCode.INVALID_PARAMETER, CognitoResponseCode.USER_NOT_CONFIRMED].includes(cognitoResponseCode);
}

/**
 * Checks if we currently have data (feature collections) stored for download etc.
 *
 * @param data an object of the form {layerName: feature collection, layername: feature collection, ...}
 * @returns {boolean}
 */
export function hasData(data) {
  if (data == null || data.layers == null || _.isEmpty(data.layers)) {
    return false;
  }

  for (let v of iterValues(data.layers)) {
    if (v != null && v.features != null && v.features.length > 0) {
      return true;
    }
  }

  return false;
}

/**
 * Checks that an email address is valid.
 * Taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 * @param {string} email - an email address
 * @returns {boolean} true if the email is valid, false if not
 */
export function isValidEmailFormat(email) {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Checks that a password matches the password policy. Currently this is minimum 8 chars.
 * This is also enforced by cognito.
 * @param {string} password - a password
 * @returns {boolean} true if the password complies with the policy, false if not.
 */
export function isValidPassword(password) {
  if (password) {
    return password.length >= 8;
  }
  return false;
}

export function getFileContents(file) {
  /**
   * `file` is a `File` object (https://developer.mozilla.org/en-US/docs/Web/API/File)
   *
   * Returns a promise that resolves to the text contained in the file.
   */
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => resolve(reader.result);
    reader.readAsText(file);
  });
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function until(pred) {
  /**
   * Returns a Promise that resolves when `pred` returns a truthy value.
   */
  return new Promise((resolve) => {
    let timer = setInterval(async () => {
      if (await pred()) {
        clearInterval(timer);
        resolve();
      }
    }, 50);
  });
}

export function* enumerate(arr) {
  for (let i = 0, l = arr.length; i < l; i++) {
    yield [i, arr[i]];
  }
}

export function reverseMap(arr, callback) {
  /**
   * `reverseMap(arr, callback)` is analogous to `arr.map(callback)` except the
   * items come out in reverse order.
   */
  let r = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    r.push(callback(arr[i], i));
  }
  return r;
}

/**
 Iterate over the [key, value] pairs the object provided. Analogous to
 `ob.iteritems` in Python.

 Usage:

 for (let [k, v] of iterItems({key1: "val1", key2: "val2"})) {
   console.log(k, v);
 }

 Gives
   "key1", "val1"
   "key2", "val2"
 */
export function* iterItems(ob) {
  for (let k in ob) {
    if (ob.hasOwnProperty(k)) {
      yield [k, ob[k]];
    }
  }
}

/**
 Iterate over the keys of the object provided. Analogous to `ob.iterkeys` in
 Python.

 Usage:

 for (let v of iterKeys({key1: "val1", key2: "val2"})) {
   console.log(v);
 }

 Gives
   "key1"
   "key2"
 */
export function* iterKeys(ob) {
  for (let k in ob) {
    if (ob.hasOwnProperty(k)) {
      yield k;
    }
  }
}

/**
 Iterate over the values of the object provided. Analogous to `ob.itervalues`
 in Python.

 Usage:

 for (let v of iterValues({key1: "val1", key2: "val2"})) {
   console.log(v);
 }

 Gives
   "val1"
   "val2"
 */
export function* iterValues(ob) {
  for (let k in ob) {
    if (ob.hasOwnProperty(k)) {
      yield ob[k];
    }
  }
}

export function connect2(mapStateToProps, mapDispatchToProps) {
  /**
   * Behaves like `connnect`, but gives the `mapDispatchToProps` function
   * access to the props generated by the `mapStateToProps` function in addition
   * to the props passed to the container.
   *
   * So `connect2`'s `mapDispatchToProps` function takes three arguments:
   * dispatch, ownProps, and stateProps (the output of `mapStateToProps`).
   */
  return connect(
    mapStateToProps,
    function _mapDispatchToProps(dispatch) {
      return { dispatch };
    },
    function mergeProps(stateProps, dispatchProps, ownProps) {
      return {
        ...stateProps,
        ...mapDispatchToProps(dispatchProps.dispatch, ownProps, stateProps),
        ...ownProps,
      };
    }
  );
}

export function defaultMergeProps(stateProps, dispatchProps, ownProps) {
  /**
   * The default `mergeProps` passed to the react-redux `connect` function.
   */
  return { ...ownProps, ...stateProps, ...dispatchProps };
}

export function reduceReducers(...reducers) {
  /**
   * Kind of like `combineReducers`, except all the reducers act at the top level.
   * Subsequent reducers can access the returns of the previous reducers.
   *
   * See __tests__/utils.test.js for example usage.
   */
  return (state, action) => {
    return reducers.reduce((currentState, reducer) => reducer(currentState, action), state);
  };
}

export function updateIn(ob, path, func) {
  /**
   Traverses `ob` by `path`, then returns a new object that is the same as `ob`
   but with the sub-object transformed by applying `func` to it. Does not
   modify `ob`.

   Also works analogously with arrays at any level in the structure if the
   corresponding element in the `path` array is a numeric index.

   Example:
      const a = {
        b: {
          c: {
            d: {
              e: 42
            }
          }
        }
      };

      >>> updateIn(a, ['b', 'c', 'd', 'e'], e => e * 2);
      {
        b: {
          c: {
            d: {
              e: 84
            }
          }
        }
      }

      Ie. the above is equivalent to:

      {
        ...a,
        b: {
          ...a.b,
          c: {
            ...a.b.c,
            d: {
              ...a.b.c.d,
              e: a.b.c.d.e * 2
            }
          }
        }
      }

    We also fill in empty objects if keys don't exist (but not for arrays).
    For example:

      >>> updateIn({}, ['a', 'b'], x => 2);
      {
        a: {
          b: {
            2
          }
        }
      }
  */

  if (!Array.isArray(path)) {
    return updateIn(ob, [path], func);
  }

  if (path.length === 0) {
    return func(ob);
  } else {
    /* eslint-disable no-lonely-if */
    if (_.isArray(ob)) {
      return ob.map((item, i) => {
        if (i === path[0]) {
          return updateIn(item, _.tail(path), func);
        } else {
          return item;
        }
      });
    } else {
      return {
        ...ob,
        [path[0]]: updateIn((ob || {})[path[0]], _.tail(path), func),
      };
    }
  }
}

/**
 * Example:
 *
 * const ob = {
 *   a: {
 *     b: 3
 *  }
 * };
 * >>> getIn(ob, ['a', 'b'])
 * 3
 */
export function getIn(ob, keyOrPath) {
  const path = Array.isArray(keyOrPath) ? keyOrPath : [keyOrPath];
  if (path.length === 0 || !ob) {
    return ob;
  } else {
    return getIn(ob[path[0]], _.tail(path));
  }
}

/**
 * Same as `updateIn` but takes a constant value instead of a function.
 */
export function setIn(ob, keyOrPath, val) {
  return updateIn(ob, keyOrPath, () => val);
}

export function sum(iterable) {
  let s = 0;
  for (let item of iterable) {
    s += item;
  }
  return s;
}

export function makeQueryString(query) {
  return _.toPairs(query)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join("&");
}

export function makeActions(prefix, actionNames) {
  /**
    >>> makeActions('PROJECT/POLYGON', [
      'BEGIN',
      'UPDATE_STATE',
    ])

    =>

    {
      'BEGIN': 'PROJECT/POLYGON/BEGIN',
      'UPDATE_STATE': 'PROJECT/POLYGON/UPDATE_STATE',
    }
   */
  let a = {};
  for (let name of actionNames) {
    a[name] = `${prefix}/${name}`;
  }
  return a;
}

export function logErrorToBrowser(e) {
  if (process.env.NODE_ENV !== "test") {
    console.error(e);
  }
}

export { tableToCsv } from "./csv";

export function setDifference(s1, s2) {
  /**
   * >>> setDifference([1, 2, 3, 4], [2, 3])
   * Set(2) {1, 4}
   *
   * Doesn't care if s1 or s2 are `Array`s or `Set`s.
   */
  let diff = new Set(s1);
  for (let item of s2) {
    diff.delete(item);
  }
  return diff;
}

export function clamp(val, min, max) {
  /**
   * Returns `val` clamped between `min` and `max`.
   */
  if (val < min) {
    return min;
  } else if (val > max) {
    return max;
  } else {
    return val;
  }
}

export function isValidLongitude(val) {
  // eslint-disable-next-line yoda
  return -180 < val && val <= 180;
}

export function isValidLatitude(val) {
  return val > -90 && val <= 90;
}

export function isValidBoundingBox(bbox) {
  if (bbox) {
    return isValidLongitude(bbox[0][0]) && isValidLatitude(bbox[0][1]) && isValidLongitude(bbox[1][0]) && isValidLatitude(bbox[1][1]);
  } else return false;
}

/**
 * Converts a turf.bbox to the format used by mapboxgl.Map
 * @param {*} bbox turf.bbox [minX, minY, maxX, maxY]
 * @returns {[[number, number], [number, number]]} BBox [[minX, minY], [maxX, maxY]] || null
 */
export function toBBox(bbox) {
  if (Array.isArray(bbox) && bbox.length === 4) {
    const boundingBox = [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];
    if (isValidBoundingBox(boundingBox)) return boundingBox;
  }
  return null;
}

export function makeUuid() {
  return uuid();
}

// Note that this conversion value is FOND specific & needs to match the
// same conversion that the backend uses.
const FEET_IN_METER = 3.2808333333356927;

export function convertMetersToFeet(length) {
  return length * FEET_IN_METER;
}

export function convertFeetToMeters(length) {
  return length / FEET_IN_METER;
}

export function metersToFeetDisplay(meters) {
  return _.round(convertMetersToFeet(meters), 4);
}

export function feetToMetersDisplay(feet) {
  return _.round(convertFeetToMeters(feet), 4);
}

export function formatUnit(systemOfMeasurement) {
  return systemOfMeasurement === "imperial" ? "feet" : "meters";
}

export function roundLength(length) {
  return Number.parseFloat(length).toFixed(2);
}

/**
 * Convert a length originally in the system of measurement specified by `from` to meters.
 *
 * Eg:
 *   toMeters(1, {from: 'metric'}) => 1
 *   toMeters(1 / 0.3048, {from: 'imperial'}) ~= 1
 */
export function toMeters(val, { from }) {
  return toSystemOfMeasurement(val, { from, to: "metric" });
}

/**
 * Convert a length originally in the system of measurement specified by `from` to feet.
 *
 * Eg:
 *   toFeet(1, {from: 'imperial'}) => 1
 *   toFeet(1 * 0.3048, {from: 'metric'}) ~= 1
 */
export function toFeet(val, { from }) {
  return toSystemOfMeasurement(val, { from, to: "imperial" });
}

/**
 * Converts a value to the best unit (the smallest unit with a value above 1)
 */
export function toBest(val, { from }) {
  return (
    convert(val)
      .from(from)
      // We exclude the smaller measurement types
      .toBest({ exclude: ["mm", "cm", "in", "yd", "ft-us"] })
  );
}

/**
 * Convert a length originally in the system of measurement specified by `from` to
 * the system of measurement specified by `to`.
 *
 *   toSystemOfMeasurement(1, {from: 'imperial', to: 'imperial'}) => 1
 *   toSystemOfMeasurement(1 / 0.3048, {from: 'imperial', to: 'metric'}) ~= 1
 *   toSystemOfMeasurement(1, {from: 'metric', to: 'metric'}) => 1
 *   toSystemOfMeasurement(1 * 0.3048, {from: 'metric', to: 'imperial'}) ~= 1
 */
export function toSystemOfMeasurement(val, { from, to }) {
  if (!val) {
    return val;
  } else if (from === to) {
    return val;
  } else if (from === "imperial" && to === "metric") {
    return convertFeetToMeters(val);
  } else if (from === "metric" && to === "imperial") {
    return convertMetersToFeet(val);
  } else {
    throw new Error(`Unrecognised system of measurement combination: ${from}-${to}`);
  }
}

/**
 * Using the same scheme for `from` and `to` as the above functions, format a
 * length as either "X feet" or "X meters" depending on the `to` system of
 * measurement.
 */
export function formatLength(val, { from, to, decimalPlaces = 2 }) {
  let outVal = toSystemOfMeasurement(val, { from, to });
  if (decimalPlaces != null) {
    outVal = outVal.toLocaleString(undefined, {
      maximumFractionDigits: decimalPlaces,
    });
  }
  return `${outVal.toLocaleString()} ${formatUnit(to)}`;
}

/**
 * Format the file size(bytes) into a string with appropriate unit.
 */
export function formatBytes(bytes, { decimalPlaces = 0 } = {}) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimalPlaces))} ${sizes[i]}`;
}

/**
 * Filters a feature collection using the mapbox filters.
 */
export const filterFeatureCollection = (featureCollection, filter) => {
  const features = (featureCollection && featureCollection.features) || [];
  return features.filter((feature) => featureFilter(filter).filter(null, feature));
};

const tryParseInt = (value) => {
  if (value === "") return value;
  if (isNaN(value)) return value;
  return parseFloat(value);
};

const tryParseBool = (value) => {
  const isString = typeof value === "string";
  if (!isString) {
    return value;
  }

  if (value.match(/^\s*true\s*$/)) {
    return true;
  } else if (value.match(/^\s*false\s*$/)) {
    return false;
  } else {
    return value;
  }
};

/**
 * Function that attemps to parse a value a user has entered
 * and determine its primitive type.  For example:
 * "8" => 8
 * "1.4" => 1.4
 * "true" => true
 */
export const parsePrimitive = (value) => {
  let parsedValue = tryParseInt(value);
  return parsedValue !== value ? parsedValue : tryParseBool(value);
};

/**
 * Formats a string or shallowly formats of an objects keys into PascalCase.
 * For example:
 * "text-font-color" => "TextFontColor"
 * { label: "test", "text-font-color": "#fff" } => { Label: "test", "TextFontColor": "#fff" }
 */
export const toPascalCase = (value, opts = { excludeID: true }) => {
  if (typeof value === "object") {
    const formattedValue = {};
    Object.keys(value).forEach((key) => {
      let newKey = startCase(camelCase(key)).replace(/ /g, "");

      if (opts.excludeID && newKey.substring(newKey.length - 2) === "Id") {
        // We incorrectly keep ID as all uppercase so we need to check if the last
        // two characters are ID and uppercase them
        newKey = newKey.replace(/w*Id\b/, (c) => c.toUpperCase());
      }
      formattedValue[newKey] = value[key];
    });

    return formattedValue;
  }

  return startCase(camelCase(value)).replace(/ /g, "");
};

/**
 * Returns a boolean indicating if the element is currently within view
 */
export const isScrolledIntoView = (el) => {
  if (!el) return false;

  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  return isVisible;
};

/**
 * A flag indicating if the code is currently being tested within the Jest environment.
 */
export function areWeTestingWithJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}
