import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { GridApi, GridOptions, GridReadyEvent, IRowNode, RowClassParams, RowDataUpdatedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";

import { MultiProject, MultiProjectArea } from "fond/types";
import { AgGrid } from "fond/widgets";

import { rankingColumns } from "../AssessPanel/rankingColumns";

interface SubareaListProps {
  /**
   * Flag indicating if the list should be placed into loading mode.
   */
  loading?: boolean;
  /**
   * The multi project to display the areas of.
   */
  multiProject: MultiProject;
  /**
   * The currently selected area ids.
   */
  selectedIds: string[];
  /**
   * A collection of area ids that make the match rows within the grid appear selected,
   * while being unselectable.
   */
  previouslySelectedIds: string[];
  /**
   * Callback event fired when row selection data changes.
   * If this is not defined then row selection will not be enabled.
   */
  onSelectionChanged?(): void;
}

const SubareaList = forwardRef<AgGridReact, SubareaListProps>(
  ({ loading = false, multiProject, onSelectionChanged, previouslySelectedIds, selectedIds }: SubareaListProps, ref) => {
    const gridApi = useRef<GridApi | null>(null);

    const getRowClass: GridOptions["getRowClass"] = useCallback(
      (params: RowClassParams<MultiProjectArea>) =>
        params.data?.ID && previouslySelectedIds.includes(params.data?.ID) ? "row-selected-disabled" : undefined,
      [previouslySelectedIds]
    );

    const rowSelection: GridOptions["rowSelection"] = useMemo(() => {
      return onSelectionChanged
        ? {
            mode: "multiRow",
            isRowSelectable: (node: IRowNode<MultiProjectArea>) => (node.data?.ID ? !previouslySelectedIds.includes(node.data?.ID) : true),
          }
        : undefined;
    }, [onSelectionChanged, previouslySelectedIds]);

    useEffect(() => {
      gridApi.current?.setGridOption("getRowClass", getRowClass);
      gridApi.current?.setGridOption("rowSelection", rowSelection);
    }, [getRowClass, rowSelection]);

    const gridOptions: GridOptions = useMemo(
      () => ({
        animateRows: true,
        rowGroupPanelShow: "never",
        sideBar: false,
        pagination: false,
        suppressMovableColumns: true,
        domLayout: "normal",
        getRowClass,
        rowSelection,
      }),
      [getRowClass, rowSelection]
    );

    const handleOnRowDataUpdated = (event: RowDataUpdatedEvent<MultiProjectArea>) => {
      event.api.forEachNode((node) => {
        if (node.data?.ID && selectedIds.includes(node.data.ID)) {
          node.setSelected(true);
        }
      });
    };

    const handleOnGridReady = (event: GridReadyEvent) => {
      gridApi.current = event.api;
    };

    return (
      <AgGrid
        ref={ref}
        columnDefs={rankingColumns}
        rowData={multiProject.Areas}
        containerProps={{
          height: 158,
        }}
        loading={loading}
        size="compact"
        variant="outlined"
        gridOptions={gridOptions}
        onGridReady={handleOnGridReady}
        onRowDataUpdated={handleOnRowDataUpdated}
        onSelectionChanged={onSelectionChanged}
      />
    );
  }
);

SubareaList.displayName = "SubareaList";
export default SubareaList;
