import { BoundaryFeatureCountRequest, BoundaryFeatureCountResponse } from "fond/types";

import { apiSlice } from "./apiSlice";

/**
 * AreaSelect API Slice
 */
export const areaSelectSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBoundaryFeatureCount: build.query<BoundaryFeatureCountResponse, BoundaryFeatureCountRequest>({
      query: (boundaries) => ({
        url: "/v2/area-select/count-features",
        method: "POST",
        body: boundaries,
      }),
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetBoundaryFeatureCountQuery, useLazyGetBoundaryFeatureCountQuery } = areaSelectSlice;
