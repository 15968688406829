import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { Feature, MultiPolygon } from "geojson";
import { get, groupBy } from "lodash";

import { selectVersionConfig, useGetMultiProjectQuery } from "fond/api";
import { useDataset } from "fond/hooks/useDataset";
import SourceAndLayers from "fond/map/SourceAndLayers";
import { Store } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { isVisible as defaultIsVisible, selectAllStylesByLayers } from "fond/utils/configurations";

interface IProps {
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  layerView: {
    [key: string]: boolean;
  };
  styles?: LayerStyle[];
  checkVisible?: (id: string, layerView: { [key: string]: boolean }) => boolean;
}

const MapContent: React.FC<IProps> = ({ layerConfigs, layerView, styles, checkVisible }: IProps) => {
  const versionId = useSelector((state: Store) => state.project.versionId);
  const configData = useSelector((state: Store) => selectVersionConfig(state, versionId));
  const { data: multiProject } = useGetMultiProjectQuery(versionId ?? skipToken);
  const accountId = multiProject?.Account.ID ?? null;

  const checkVisibility = useCallback(
    (id: string, view: { [layerId: string]: boolean }) => {
      if (checkVisible) {
        return checkVisible(id, view);
      }
      return defaultIsVisible(configData, { id, layerView: view });
    },
    [configData, checkVisible]
  );
  const { dataset: fccDataset, tileUrl: fccTileUrl } = useDataset({ key: "fcc-2024", accountId });
  const { dataset: cbgDataset, tileUrl: cbgTileUrl } = useDataset({ key: "census-block-groups-2024", accountId });
  const { dataset: soilDataset, tileUrl: soilTileUrl } = useDataset({ key: "soil-2025", accountId });

  const features = useMemo(() => {
    const polygons: Feature<MultiPolygon>[] = [];

    if (multiProject?.Boundary) {
      polygons.push({
        type: "Feature",
        properties: {
          id: multiProject.ID,
          boundaryId: multiProject.ID,
          type: "boundary",
        },
        geometry: multiProject.Boundary,
      });
    }

    // Add the sub area boundaries
    multiProject?.Areas.forEach((area) => {
      polygons.push({
        type: "Feature",
        properties: {
          id: area.ID,
          boundaryId: area.ID,
          name: area.Name,
          score: area.Score,
          type: "subarea",
        },
        geometry: area.Boundary,
      });
    });

    return polygons;
  }, [multiProject]);

  // The configuration data needs to be jumbled up together for e.g. the legend; however SourceAndLayers/mapbox doesn't
  // like us providing the same layer configuration to multiple sources, so separate those configs here.
  const data = useMemo(() => {
    const configs = groupBy(
      layerConfigs.filter(({ Exclude }) => Exclude !== true),
      "Key"
    );

    const subareaLayerConfigs = get(configs, "subareas", []);
    const fccLayerConfigs = get(configs, "census-tracts", []);
    const cbgLayerConfigs = get(configs, "census-block-groups", []);
    const soilLayerConfigs = get(configs, "soil", []);

    return {
      subareaLayerConfigs,
      subareaStyles: selectAllStylesByLayers(subareaLayerConfigs, styles),
      fccLayerConfigs,
      fccStyles: selectAllStylesByLayers(fccLayerConfigs, styles),
      cbgLayerConfigs,
      cbgStyles: selectAllStylesByLayers(cbgLayerConfigs, styles),
      soilLayerConfigs,
      soilStyles: selectAllStylesByLayers(soilLayerConfigs, styles),
    };
  }, [layerConfigs, styles]);

  const fccSource = useMemo(
    () => ({
      type: "vector",
      tiles: [fccTileUrl],
      promoteId: "id",
      minzoom: fccDataset?.TileCollection?.MinZoom,
      maxzoom: fccDataset?.TileCollection?.MaxZoom,
    }),
    [fccDataset?.TileCollection?.MaxZoom, fccDataset?.TileCollection?.MinZoom, fccTileUrl]
  );
  const cbgSource = useMemo(
    () => ({
      type: "vector",
      tiles: [cbgTileUrl],
      promoteId: "id",
      minzoom: cbgDataset?.TileCollection?.MinZoom,
      maxzoom: cbgDataset?.TileCollection?.MaxZoom,
    }),
    [cbgDataset?.TileCollection?.MaxZoom, cbgDataset?.TileCollection?.MinZoom, cbgTileUrl]
  );
  const soilSource = useMemo(
    () => ({
      type: "vector",
      tiles: [soilTileUrl],
      promoteId: "id",
      minzoom: soilDataset?.TileCollection?.MinZoom,
      maxzoom: soilDataset?.TileCollection?.MaxZoom,
    }),
    [soilDataset?.TileCollection?.MaxZoom, soilDataset?.TileCollection?.MinZoom, soilTileUrl]
  );

  const multiProjectSource = useMemo(
    () => ({
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features,
      },
      promoteId: "id",
    }),
    [features]
  );

  return (
    <>
      {/*
       * Note that subareas need to be rendered before the overlays SourceAndLayers to ensure
       * that the beforeId exists prior to rendering.
       */}
      <SourceAndLayers
        sourceId="multiProject-source"
        source={multiProjectSource}
        styles={data.subareaStyles}
        layers={data.subareaLayerConfigs}
        layerVisibilities={layerView}
        isVisible={checkVisibility}
      />
      {fccDataset && (
        <SourceAndLayers
          sourceId={fccDataset.Key}
          source={fccSource}
          styles={data.fccStyles}
          layers={data.fccLayerConfigs}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
          beforeId={data.subareaStyles.at(-1)?.ID}
        />
      )}
      {cbgDataset && (
        <SourceAndLayers
          sourceId={cbgDataset.Key}
          source={cbgSource}
          styles={data.cbgStyles}
          layers={data.cbgLayerConfigs}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
          beforeId={data.subareaStyles.at(-1)?.ID}
        />
      )}
      {soilDataset && (
        <SourceAndLayers
          sourceId={soilDataset.Key}
          source={soilSource}
          styles={data.soilStyles}
          layers={data.soilLayerConfigs}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
          beforeId={data.subareaStyles.at(-1)?.ID}
        />
      )}
    </>
  );
};

export default MapContent;
