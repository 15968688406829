import React, { useMemo, useRef, useState } from "react";
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Directions, Highlight, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, TextField, Tooltip } from "@mui/material";
import area from "@turf/area";
import { feature } from "@turf/helpers";
import mapboxgl from "mapbox-gl";

import { useGetMultiProjectQuery, useGetMultiProjectStatisticsQuery } from "fond/api";
import { zoomTo } from "fond/map/redux";
import { highlightFeatures, selectFeature } from "fond/project/redux";
import { MultiProjectArea, MultiProjectAreaStatistic } from "fond/types";
import { sqMetresToSqMiles, sqMilesToSqMetres } from "fond/utils/area";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { formatFractionPercent, formatNumber } from "fond/utils/number";
import { AgGrid, BlockSpinner } from "fond/widgets";

type Row = MultiProjectAreaStatistic & MultiProjectArea;

/**
 * To make sure the tooltip loads in the correct window.document
 * when the component is loaded within a floating window we
 * need to disable the use of a portal.
 */
const tooltipPopperProps = { disablePortal: true };

const columns: Array<ColDef | ColGroupDef> = [
  {
    field: "Name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "Boundary",
    headerName: "Area",
    width: 100,
    valueGetter: (params: ValueGetterParams) => formatNumber(sqMetresToSqMiles(area(params.data.Boundary)), 2),
  },
  {
    field: "Score",
    headerName: "Score",
    width: 75,
    valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value, 0),
  },
  {
    headerName: "Addresses",
    children: [
      {
        field: "AddressCounts.AddressCount",
        headerName: "Count",
        width: 100,
      },
      {
        field: "AddressDensity.AddressesPerSquareMeters",
        headerName: "Density (sq mi)",
        width: 125,
        valueGetter: (params: ValueGetterParams) => formatNumber(sqMilesToSqMetres(params.data.AddressDensity.AddressesPerSquareMeters), 2),
      },
      {
        field: "AddressBreakdown.Sdu.Proportion",
        headerName: "SDU (%)",
        valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
        width: 125,
      },
      {
        field: "AddressUnderservedProportion.AddressUnderservedProportion",
        headerName: "Underserved (%)",
        valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
        width: 125,
      },
    ],
  },
  {
    headerName: "Fiber coverage",
    children: [
      {
        field: "ProviderFiberCoverage.AT&T.FiberCoverageProportion",
        headerName: "AT&T",
        valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
        width: 100,
      },
      {
        field: "ProviderFiberCoverage.T-Mobile.FiberCoverageProportion",
        headerName: "T-Mobile",
        valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
        width: 100,
      },
      {
        field: "ProviderFiberCoverage.Verizon.FiberCoverageProportion",
        headerName: "Verizon",
        valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
        width: 100,
      },
    ],
  },
];

const gridOptions: GridOptions = {
  animateRows: true,
  rowGroupPanelShow: "never",
  sideBar: false,
  pagination: false,
  rowSelection: {
    mode: "multiRow",
    checkboxes: false,
    headerCheckbox: false,
    enableClickSelection: true,
  },
  domLayout: "normal",
};

const FeaturesTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const multiProjectId = useAppSelector((state) => state.project.projectId);
  const { data: multiProject, isLoading: isLoadingProject } = useGetMultiProjectQuery(multiProjectId);
  const { data: statistics, isLoading: isLoadingStatistics } = useGetMultiProjectStatisticsQuery(multiProjectId);
  const [selectedFeatures, setSelectedFeatures] = useState<Partial<mapboxgl.MapboxGeoJSONFeature>[]>([]);
  const gridRef = useRef<AgGridReact | null>(null);

  const rowData: Row[] = useMemo(() => {
    if (!multiProject || !statistics) return [];
    return statistics.Areas.map((stats) => ({ ...stats, ...multiProject.Areas.find((a) => a.ID === stats.Area.ID) }) as Row);
  }, [multiProject, statistics]);

  if (!multiProject) return <BlockSpinner />;

  /**
   * Provides a custom set of context menu items (right click on row)
   */
  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    return [
      "copy",
      "copyWithHeaders",
      "separator",
      "export",
      "separator",
      {
        // custom item
        name: "Zoom to Feature",
        action: () => {
          if (params.node) {
            const { Boundary, ID } = params.node.data;
            const mapboxFeature: Partial<mapboxgl.MapboxGeoJSONFeature> = {
              ...feature(Boundary, { boundaryId: ID }, { id: ID }),
              source: "multiProject-source",
            };
            // Zoom to feature or the group of features
            dispatch(zoomTo([mapboxFeature]));
          }
        },
        icon: `<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M21.71 11.29l-9-9a.9959.9959 0 00-1.41 0l-9 9c-.39.39-.39 1.02 0 1.41l9 9c.39.39 1.02.39 1.41 0l9-9c.39-.38.39-1.01 0-1.41zM14 14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 3.5-3.5 3.5z"></path></svg>`,
      },
    ];
  };

  /**
   * Callback function called when a row is double clicked
   * Feature is zoomed to & selected
   */
  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    dispatch(zoomTo(selectedFeatures));
    dispatch(selectFeature(selectedFeatures[0]));
    dispatch(
      highlightFeatures({
        projectId: multiProjectId,
        features: selectedFeatures,
      })
    );
  };

  /**
   * Callback function called when row selection changes withing the grid.
   * Enable zoom if at least one feature has geometry.
   */
  const onSelectionChanged = (event: SelectionChangedEvent<Row>) => {
    const features: Partial<mapboxgl.MapboxGeoJSONFeature>[] = [];
    const selectedNodes = event.api.getSelectedNodes();
    selectedNodes.forEach(({ data }) => {
      if (data?.Boundary) {
        const mapboxFeature: Partial<mapboxgl.MapboxGeoJSONFeature> = {
          ...feature(data.Boundary, { boundaryId: data.ID }, { id: data.ID }),
          source: "multiProject-source",
        };
        features.push(mapboxFeature);
      }
    });
    setSelectedFeatures(features);
  };

  const handleHighlight = () => {
    dispatch(
      highlightFeatures({
        projectId: multiProjectId,
        features: selectedFeatures,
      })
    );
  };

  /**
   * Callback function for zooming to selected features within the table
   */
  const handleZoomTo = () => {
    dispatch(zoomTo(selectedFeatures));
  };

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    gridRef.current?.api?.setGridOption("quickFilterText", event.target.value ?? "");
  };

  return (
    <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center" m={0.5}>
        <TextField
          variant="outlined"
          data-testid="ag-grid-external-input"
          margin="dense"
          name="search"
          onChange={handleOnFilterChange}
          placeholder="Search..."
          inputProps={{ sx: { p: 1, fontSize: 12 } }}
          InputProps={{
            startAdornment: <Search />,
          }}
          sx={{ m: 0 }}
        />
        <Box height="100%" mx={1}>
          <Divider orientation="vertical" />
        </Box>
        <ButtonGroup variant="outlined">
          <Tooltip title="Zoom to subarea" PopperProps={tooltipPopperProps}>
            <span>
              <Button
                variant="text"
                size="small"
                disabled={!selectedFeatures.length}
                onClick={handleZoomTo}
                startIcon={<Directions color={selectedFeatures.length ? "primary" : "disabled"} />} // TODO
              >
                Zoom
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Highlight Subareas" PopperProps={tooltipPopperProps}>
            <span>
              <Button
                variant="text"
                size="small"
                disabled={!selectedFeatures.length}
                onClick={handleHighlight}
                startIcon={<Highlight color={selectedFeatures.length ? "primary" : "disabled"} />}
              >
                Highlight
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </Box>

      <Box flexGrow={1}>
        <AgGrid
          ref={gridRef}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          variant="borderless"
          size="compact"
          columnDefs={columns}
          rowData={rowData}
          loading={isLoadingProject || isLoadingStatistics}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
        />
      </Box>
    </Box>
  );
};

export default FeaturesTable;
