import { styled, Tab } from "@mui/material";

export const ReportConfigurationForm = styled("form")`
  display: flex;
  flex-direction: column;
`;

export const ReportConfigurationTab = styled(Tab)`
  &.validation-error {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
