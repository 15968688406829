import React, { useMemo } from "react";
import { IRowNode } from "@ag-grid-community/core";
import { AttachMoney, Business, MonetizationOn, MultipleStop } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";

import { formatNumber } from "fond/utils/number";
import GridCard from "fond/widgets/GridCard";

interface IProps {
  renderedNodes: IRowNode<any>[];
}

const CityReportCallout: React.FC<IProps> = ({ renderedNodes }: IProps) => {
  const rowData = useMemo(() => {
    return renderedNodes.map((node) => node.data);
  }, [renderedNodes]);

  const rowSummary: {
    subareaCount: number | null;
    totalPassings: number | null;
    netCost: number | null;
    netRevenue: number | null;
  } = useMemo(() => {
    return {
      subareaCount: rowData.length,
      totalPassings: rowData.reduce((acc, row) => acc + row.TotalPassings, 0),
      netCost: rowData.reduce((acc, row) => acc + row.NetCost, 0),
      netRevenue: rowData.reduce((acc, row) => acc + row.NetRevenue, 0),
    };
  }, [rowData]);

  const reportCallouts = useMemo(() => {
    return [
      {
        key: "subareaCount",
        title: "Subareas",
        icon: <Business color="secondary" />,
        data: formatNumber(rowSummary.subareaCount),
      },
      {
        key: "totalPassings",
        title: "Passings",
        icon: <MultipleStop color="secondary" />,
        data: formatNumber(rowSummary.totalPassings),
      },
      { key: "netCost", title: "Cost", icon: <AttachMoney color="secondary" />, data: formatNumber(rowSummary.netCost, 0) },
      { key: "netRevenue", title: "Revenue", icon: <MonetizationOn color="secondary" />, data: formatNumber(rowSummary.netRevenue, 0) },
    ];
  }, [rowSummary]);

  return (
    <Grid container spacing={2.5}>
      {reportCallouts.map(({ key, title, icon, data }) => (
        <GridCard key={key} breakpoints={{ lg: 3, sm: 6, xs: 12 }}>
          <Box height={105} display="flex" flexDirection="column" justifyContent="space-around" p={1.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2}>
              <Typography variant="content" fontSize={12}>
                {title}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" pl={2}>
              {icon}
              <Typography variant="h3" component="span" fontWeight={700} sx={{ ml: 2, mr: 1 }}>
                {data}
              </Typography>
            </Box>
          </Box>
        </GridCard>
      ))}
    </Grid>
  );
};

export default CityReportCallout;
