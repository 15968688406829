import { IGlobalAttributes, IJsonBorderNode, IJsonModel, IJsonRowNode } from "flexlayout-react/declarations/model/IJsonModel";

import { Widget } from "fond/types";

// Default project widget definitions
export const projectWidgets = {
  [Widget.Comments]: {
    id: Widget.Comments,
    type: "tab",
    name: "Comments",
    altName: "Comments",
    component: Widget.Comments,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
  [Widget.DesignPanel]: {
    id: Widget.DesignPanel,
    type: "tab",
    name: "Design Tools",
    altName: "Design",
    component: Widget.DesignPanel,
    config: {},
    enableClose: true,
  },
  [Widget.Properties]: {
    id: Widget.Properties,
    type: "tab",
    name: "Properties",
    altName: "Properties",
    component: Widget.Properties,
    config: {},
    enableClose: true,
  },
  [Widget.ErrorLog]: {
    id: Widget.ErrorLog,
    type: "tab",
    name: "Error log",
    altName: "Error Log",
    component: Widget.ErrorLog,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
  [Widget.FeaturesList]: {
    id: Widget.FeaturesList,
    type: "tab",
    name: "Features",
    altName: "Features",
    component: Widget.FeaturesList,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
  [Widget.Legend]: {
    id: Widget.Legend,
    type: "tab",
    name: "Legend",
    altName: "Legend",
    component: Widget.Legend,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
  [Widget.Map]: {
    id: Widget.Map,
    type: "tab",
    name: "Map",
    component: Widget.Map,
    config: {},
  },
  [Widget.Versions]: {
    id: Widget.Versions,
    type: "tab",
    name: "Versions",
    component: Widget.Versions,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
};

// Default multiProject widget definitions
export const multiProjectWidgets = {
  [Widget.CityDesign]: {
    id: Widget.CityDesign,
    type: "tab",
    name: "Design Tools",
    altName: "Design",
    component: Widget.CityDesign,
    config: {},
    enableClose: true,
  },
  [Widget.Legend]: {
    id: Widget.Legend,
    type: "tab",
    name: "Legend",
    altName: "Legend",
    component: Widget.Legend,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
  [Widget.CityMap]: {
    id: Widget.CityMap,
    type: "tab",
    name: "Map",
    altName: "Map",
    component: Widget.CityMap,
    config: {},
  },
  [Widget.CityProperties]: {
    id: Widget.CityProperties,
    type: "tab",
    name: "Properties",
    altName: "Properties",
    component: Widget.CityProperties,
    config: {},
  },
  [Widget.SubareaList]: {
    id: Widget.SubareaList,
    type: "tab",
    name: "Features",
    altName: "Features",
    component: Widget.SubareaList,
    config: {
      enableFloat: true,
    },
    enableClose: true,
  },
};

export const widgets = { ...projectWidgets, ...multiProjectWidgets };
export const globalDefaults: IGlobalAttributes = {
  /**
   * Size of the border bars in pixels; if left as 0 then the value will be calculated from the current fontSize
   */
  borderBarSize: 32,
  /**
   * Width in pixels of all splitters between tabsets/borders
   */
  splitterSize: 1,
  /**
   * Additional width in pixels of the splitter hit test area
   */
  splitterExtra: 4,
  /**
   * Allow user to close all tabs via close button.
   */
  tabEnableClose: false,
  /**
   * Allow user to drag all tabs to new location
   */
  tabEnableDrag: true,
  /**
   * Allow user to rename all tabs by double clicking
   */
  tabEnableRename: false,
  /**
   * Height of tabset tab bar in pixels; if left as 0 then the value will be calculated from the current fontSize
   */
  tabSetTabStripHeight: 32,
  /**
   * Use the legacy text only overflow menu (preferred look).
   */
  legacyOverflowMenu: true,
};

/**
 * This is the default left and right border definitions.
 *
 * See: https://www.npmjs.com/package/flexlayout-react#border-attributes
 */
export const bordersDefaults: IJsonBorderNode[] = [
  {
    type: "border",
    location: "bottom",
    enableAutoHide: true,
    size: 400,
    children: [projectWidgets[Widget.FeaturesList], projectWidgets[Widget.ErrorLog]],
  },
  {
    type: "border",
    location: "left",
    enableAutoHide: true,
    size: 350,
    children: [projectWidgets[Widget.DesignPanel], projectWidgets[Widget.Comments], projectWidgets[Widget.Properties]],
  },
  {
    type: "border",
    location: "right",
    enableAutoHide: true,
    children: [],
  },
];

/**
 * This is the default tab definitions.
 *
 * See: https://www.npmjs.com/package/flexlayout-react#tabset-attributes
 */
export const layoutDefaults: IJsonRowNode = {
  id: "row_main",
  type: "row",
  weight: 100,
  children: [
    {
      id: Widget.MapLayout,
      type: "tabset",
      weight: 75,
      active: true,
      enableDrop: false,
      // We hide the tab strip as the Maps sub layout has its own
      enableTabStrip: false,
      children: [
        {
          id: "maps",
          type: "tab",
          name: "Maps",
          component: Widget.MapLayout,
        },
      ],
    },
    {
      id: "row_right",
      type: "row",
      weight: 25,
      children: [
        {
          id: "legend_tabset",
          type: "tabset",
          weight: 50,
          children: [projectWidgets[Widget.Legend]],
        },
        {
          id: "versions_tabset",
          type: "tabset",
          weight: 30,
          children: [projectWidgets[Widget.Versions]],
        },
      ],
    },
  ],
};

export const defaultLayout: IJsonModel = {
  global: globalDefaults,
  borders: bordersDefaults,
  layout: layoutDefaults,
};

export const multiProjectBordersDefaults: IJsonBorderNode[] = [
  {
    type: "border",
    location: "bottom",
    enableAutoHide: true,
    size: 400,
    children: [multiProjectWidgets[Widget.SubareaList]],
  },
  {
    type: "border",
    location: "left",
    enableAutoHide: true,
    size: 350,
    children: [multiProjectWidgets[Widget.CityDesign]],
  },
  {
    type: "border",
    location: "right",
    enableAutoHide: true,
    children: [],
  },
];

export const multiProjectLayoutDefaults: IJsonRowNode = {
  id: "row_main",
  type: "row",
  weight: 100,
  children: [
    {
      id: Widget.MapLayout,
      type: "tabset",
      weight: 75,
      active: true,
      enableDrop: false,
      // We hide the tab strip as the Maps sub layout has its own
      enableTabStrip: false,
      children: [
        {
          id: "maps",
          type: "tab",
          name: "Maps",
          component: Widget.MapLayout,
        },
      ],
    },
    {
      id: "row_right",
      type: "row",
      weight: 25,
      children: [
        {
          id: "legend_tabset",
          type: "tabset",
          weight: 60,
          children: [multiProjectWidgets[Widget.Legend]],
        },
        {
          id: "properties_tabset",
          type: "tabset",
          weight: 40,
          children: [multiProjectWidgets[Widget.CityProperties]],
        },
      ],
    },
  ],
};

export const defaultMultiProjectLayout: IJsonModel = {
  global: globalDefaults,
  borders: multiProjectBordersDefaults,
  layout: multiProjectLayoutDefaults,
};

/**
 * The maps within the layout are loaded within a sub layout.
 * This provides the UI with the ability to show multiple maps &
 * save that sub-layout per project.
 */
export const defaultMapSubLayout: IJsonModel = {
  global: {
    ...globalDefaults,
  },
  borders: [],
  layout: {
    id: "row",
    type: "row",
    children: [
      {
        id: "row_tabset",
        type: "tabset",
        weight: 50,
        selected: 0,
        children: [projectWidgets[Widget.Map]],
        active: true,
      },
    ],
  },
};

export const multiProjectDefaultMapSubLayout: IJsonModel = {
  global: {
    ...globalDefaults,
  },
  borders: [],
  layout: {
    id: "row",
    type: "row",
    children: [
      {
        id: "row_tabset",
        type: "tabset",
        weight: 50,
        selected: 0,
        children: [multiProjectWidgets[Widget.CityMap]],
        active: true,
      },
    ],
  },
};
