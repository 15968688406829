import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";

import { selectExportsByProjectId, useGetExportsQuery } from "fond/api";
import { getCurrentProject } from "fond/project/redux";
import { Export, ExportAccordionPanel, ExportsByStatus, ExportStatus, Store } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { isCollaborationProject } from "fond/utils/project";
import { Modal } from "fond/widgets";

import ExportAccordion from "../ExportAccordion";
import NewExportModal from "../NewExportModal";

import { StyledExportIcon } from "./ExportModal.styles";

interface ExportModalProps {
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
}

const ExportModal: React.FC<ExportModalProps> = ({ onClose }: ExportModalProps) => {
  /**
   * Controls whether the polling should be active.
   */
  const [polling, setPolling] = useState(true);

  const [newExportModal, showNewExportModal] = useState(false);
  const project = useSelector((state: Store) => getCurrentProject(state.project));
  useGetExportsQuery(project?.ID, {
    skip: !project?.ID,
    pollingInterval: polling ? 1500 : 0,
    refetchOnMountOrArgChange: true,
  });
  const canEditExport = permissionCheck(project?.Permission.Level, Actions.PROJECT_EXPORT_VIEW);
  const exports = useSelector((state: Store): Export[] => (project && selectExportsByProjectId(state, project.ID)) || []);
  const [expanded, setExpanded] = useState<string | false>(ExportAccordionPanel.Complete);
  const exportsByStatus: ExportsByStatus = useMemo(
    () => ({
      InProgress: exports.filter((item: Export) => [ExportStatus.Pending, ExportStatus.InProgress].includes(item.Status.Value)),
      Complete: exports.filter((item: Export) => item.Status.Value === ExportStatus.Complete),
      Error: exports.filter((item: Export) => item.Status.Value === ExportStatus.Error),
    }),
    [exports]
  );
  const subTitle = isCollaborationProject(project) ? "COLLABORATION" : "PLANNER";

  const onExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = () => {
    if (canEditExport) {
      showNewExportModal(true);
    }
  };

  /*
   * Determine when the polling should be active/inactive.
   */
  useEffect(() => {
    // If all exports are turned completed, expand the export project accordion.
    if (polling && exportsByStatus.InProgress.length === 0) {
      setExpanded(ExportAccordionPanel.Complete);
    }
  }, [exportsByStatus.InProgress.length, polling]);

  useEffect(() => {
    setPolling(Boolean(exportsByStatus.InProgress.length));
  }, [exportsByStatus.InProgress.length]);

  return (
    <Modal
      open
      onClose={onClose}
      variant="primary"
      header="Export"
      headerIcon={<StyledExportIcon />}
      headerSubtitle={subTitle}
      data-testid="export-modal"
      content={
        <>
          <Box>
            <Button data-testid="create-new-export-button" variant="contained" color="primary" onClick={handleClick} disabled={!canEditExport}>
              Create new export
            </Button>
          </Box>
          {newExportModal && (
            <NewExportModal onClose={() => showNewExportModal(false)} onPostExport={() => setExpanded(ExportAccordionPanel.InProgress)} />
          )}
          <ExportAccordion exportsByStatus={exportsByStatus} expanded={expanded} onExpand={onExpand} />
        </>
      }
      actions={
        <Button data-testid="cancel-button" color="primary" onClick={onClose}>
          Close
        </Button>
      }
    />
  );
};

export default ExportModal;
