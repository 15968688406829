import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import { TreeItem, TreeItemIndex } from "ui/Tree";

import { hydrateGroup, hydrateLayer } from "fond/api";
import { commentGroupConfig, commentLayerConfigs } from "fond/layers";
import { LegendItemType } from "fond/map/Legend/Legend";
import { DropResult } from "fond/redux/styles";
import { Configuration, CTSType, GroupConfig, GroupConfigHydrated } from "fond/types";
import { LayerConfig, LayerConfigHydrated, LayerStyle, SublayerConfig, SublayerConfigHydrated } from "fond/types/ProjectLayerConfig";

import { pickIDs } from "./entity";

interface IUpdateFunctions {
  updateGroupConfig: (groupConfig: GroupConfigHydrated) => void;
  updateLayerConfig: (layerConfig: LayerConfigHydrated) => void;
  updateSublayerConfig: (sublayerConfig: SublayerConfigHydrated) => void;
  updateStyleConfig: (style: LayerStyle) => void;
}

export const handleLayersOrderChange = (
  dropResult: DropResult,
  configurations: Configuration,
  updateCachedDraft: (cb: (draft: MaybeDrafted<Configuration>) => void) => void,
  updateFunctions: IUpdateFunctions
): void => {
  const {
    id,
    source: { index: sourceIndex, type: sourceType },
    destination: { id: destinationId, type: destinationType, index: destinationIndex },
  } = dropResult;
  const draftConfiguration = structuredClone(configurations);
  const { updateGroupConfig, updateLayerConfig, updateStyleConfig, updateSublayerConfig } = updateFunctions;

  switch (destinationType) {
    case "MapLayerConfig": {
      const entity = draftConfiguration.Data.entities[id] as GroupConfig | LayerConfig | undefined;

      if (entity) {
        // Confirm if we are re-ordering an existing item or adding one
        const insertIndex = destinationIndex !== undefined ? destinationIndex : draftConfiguration.MapChildren.length || 0;
        if (entity.ParentID === null) {
          // Re-ordering existing
          const itemId = draftConfiguration.MapChildren.splice(sourceIndex, 1)[0];
          draftConfiguration.MapChildren.splice(insertIndex, 0, itemId);
        } else if ((entity.Type === "GROUP" || entity.Type === "LAYER") && entity.ParentID) {
          // Adding a new LayerConfig or GroupConfig to the MapLayerConfig
          draftConfiguration.MapChildren.splice(insertIndex, 0, entity.ID);
          // Remove the layer from its original GroupConfig & remove its GroupConfigID
          const oldGroup = draftConfiguration.Data.entities[entity.ParentID] as GroupConfig | undefined;
          oldGroup?.Children.splice(sourceIndex, 1);
        }

        entity.Position = insertIndex;
        entity.ParentID = null;

        // Update affected enity
        if (entity.Type === "LAYER") updateLayerConfig(hydrateLayer(draftConfiguration.Data.entities, entity) as LayerConfigHydrated);
        if (entity.Type === "GROUP") updateGroupConfig(hydrateGroup(draftConfiguration.Data.entities, entity) as GroupConfigHydrated);
      }
      break;
    }
    /**
     * Groups or Layers within a GroupConfig are being added or re-ordered
     */
    case "GROUP": {
      const entity = draftConfiguration.Data.entities[id] as LayerConfig | GroupConfig | undefined;
      if (entity && (entity.Type === "GROUP" || entity.Type === "LAYER")) {
        if (entity.ParentID && destinationId === entity.ParentID) {
          // Re-ordering an existing layer within its own group
          const group = draftConfiguration.Data.entities[entity.ParentID] as GroupConfig | undefined;
          if (group) {
            const itemId = group.Children.splice(sourceIndex, 1)[0];
            const insertIndex = destinationIndex !== undefined ? destinationIndex : group.Children.length;
            group.Children.splice(insertIndex, 0, itemId);
            entity.Position = insertIndex;
            if (entity.Type === "LAYER") updateLayerConfig(hydrateLayer(draftConfiguration.Data.entities, entity) as LayerConfigHydrated);
            if (entity.Type === "GROUP") updateGroupConfig(hydrateGroup(draftConfiguration.Data.entities, entity) as GroupConfigHydrated);
          }
        } else {
          // Adding a layer to the group
          const group = draftConfiguration.Data.entities[destinationId] as GroupConfig | undefined;
          if (group) {
            // Remove the item from its previous location
            if (sourceType === "MapLayerConfig") {
              draftConfiguration.MapChildren.splice(sourceIndex, 1);
            } else if (sourceType === "GROUP" && entity.ParentID) {
              const oldGroup = draftConfiguration.Data.entities[entity.ParentID] as GroupConfig | undefined;
              oldGroup?.Children.splice(sourceIndex, 1);
            }

            const insertIndex = destinationIndex !== undefined ? destinationIndex : group.Children.length;
            entity.Position = insertIndex;
            entity.ParentID = group.ID;
            group.Children.splice(insertIndex, 0, entity.ID);
            if (entity.Type === "LAYER") updateLayerConfig(hydrateLayer(draftConfiguration.Data.entities, entity) as LayerConfigHydrated);
            if (entity.Type === "GROUP") updateGroupConfig(hydrateGroup(draftConfiguration.Data.entities, entity) as GroupConfigHydrated);
          }
        }
      }

      break;
    }

    /**
     * Sublayers or Styles within a LayerConfig are being re-ordered
     */
    case "LAYER": {
      const child = draftConfiguration.Data.entities[id] as SublayerConfig | LayerStyle;
      if (child && child.Type === "SUBLAYER" && child.ParentID) {
        const layer = draftConfiguration.Data.entities[child.ParentID];
        if (layer && layer.Type === "LAYER") {
          // Re-ordering a sublayer within the layer
          // Note: That due to layers having both styles and sublayers the sourceIndex & destination index
          // need to be offset by the number of Styles within this layer.
          const insertIndex = destinationIndex !== undefined ? destinationIndex : layer.Children.length;
          const offsetSourceIndex = sourceIndex - layer.Styles.length;
          const offsetDestinationIdex = Math.max(insertIndex - layer.Styles.length, 0);
          layer.Children.splice(offsetSourceIndex, 1);
          layer.Children.splice(offsetDestinationIdex, 0, child.ID);
          child.Position = insertIndex;
          updateSublayerConfig(hydrateLayer(draftConfiguration.Data.entities, child) as SublayerConfigHydrated);
          updateLayerConfig(hydrateLayer(draftConfiguration.Data.entities, layer) as LayerConfigHydrated);
        }
      } else if (child && child.Type === "STYLE" && child.ConfigurationID) {
        const layer = draftConfiguration.Data.entities[child.ConfigurationID] as LayerConfig | undefined;
        if (layer && layer.Type === "LAYER") {
          // Re-ordering a style within the layer
          const itemId = layer.Styles.splice(sourceIndex, 1)[0];
          const item = draftConfiguration.Data.entities[itemId] as LayerStyle;
          const insertIndex = destinationIndex !== undefined ? destinationIndex : layer.Styles.length;
          item.Position = insertIndex;
          updateStyleConfig(item);

          layer.Styles.splice(insertIndex, 0, itemId);
          updateLayerConfig(hydrateLayer(draftConfiguration.Data.entities, layer) as LayerConfigHydrated);
        }
      }
      break;
    }
    case "SUBLAYER": {
      const style = draftConfiguration.Data.entities[id] as LayerStyle;
      if (style && style.ConfigurationID) {
        const sublayer = draftConfiguration.Data.entities[style.ConfigurationID];
        if (sublayer && sublayer.Type === "SUBLAYER") {
          sublayer.Styles.splice(sourceIndex, 1);
          const insertIndex = destinationIndex !== undefined ? destinationIndex : sublayer.Styles.length;
          style.Position = insertIndex;
          updateStyleConfig(style);
          sublayer.Styles.splice(insertIndex, 0, style.ID);
          updateSublayerConfig(hydrateLayer(draftConfiguration.Data.entities, sublayer) as SublayerConfigHydrated);
        }
      }
      break;
    }

    default: {
      console.warn("Invalid drop destination");
    }
  }

  updateCachedDraft((draft) => {
    Object.assign(draft, draftConfiguration);
  });
};

/**
 * Returns a list of all styles that are descendants of the layer.
 * I.e. includes sublayer styles
 */
export const selectAllStylesByLayer = (layer: LayerConfig | SublayerConfig, config: Configuration): LayerStyle[] => {
  const layerStyles: LayerStyle[] = [];
  layer.Styles.forEach((styleId: string) => {
    const style = config.Data.entities[styleId] as LayerStyle;
    if (style) layerStyles.push(style);
  });
  if (layer.Type === "LAYER") {
    layer.Children.forEach((sublayerId) => {
      const sublayer = config.Data.entities[sublayerId] as SublayerConfig;
      sublayer?.Styles.forEach((styleId) => {
        const style = config.Data.entities[styleId] as LayerStyle;
        if (style) layerStyles.push(style);
      });
    });
  }

  return layerStyles;
};

export const selectAllStylesByLayers = (layers: Array<LayerConfig | SublayerConfig>, styles: LayerStyle[] = []): LayerStyle[] => {
  const layerStyles: LayerStyle[] = [];
  layers.forEach((layer) => {
    layer.Styles.forEach((styleId) => {
      const style = styles.find(({ ID }) => ID === styleId);
      if (style) layerStyles.push(style);
    });
  });

  return layerStyles;
};

/**
 * Helper function at looks at the current layerView visibility settings
 * & ancestors to determine if a map layer is visible.
 */
export const isVisible = ({ Data }: Configuration, args: { id: string; layerView: { [key: string]: boolean } }): boolean => {
  const { id, layerView } = args;
  const ancestorsCheck = (itemId: string): boolean => {
    const item = Data.entities[itemId] || commentLayerConfigs.entities[itemId] || (itemId === commentGroupConfig.ID ? commentGroupConfig : undefined);

    // fallback to visible
    if (!item) return true;

    const visible = layerView[item.ID] !== undefined ? layerView[item.ID] : item.Type === "STYLE" || item.IsVisible;
    if (item.Type === "STYLE") {
      return ancestorsCheck(item.ConfigurationID);
    } else if (item.Type === "SUBLAYER") {
      return visible && ancestorsCheck(item.ParentID);
    } else if (item.Type === "LAYER" || item.Type === "CommentConfig") {
      if (item.ParentID) {
        return visible && ancestorsCheck(item.ParentID);
      }
    } else if (item.Type === "GROUP") {
      if (item.ParentID) {
        return visible && ancestorsCheck(item.ParentID);
      }
    }
    return visible;
  };
  return ancestorsCheck(id);
};

/**
 * Generate the data source for the Tree component based on the version configuration
 */
export const generateTreeItems = ({
  config,
  showStyles,
}: {
  config: Configuration;
  showStyles: boolean;
}): Record<TreeItemIndex, TreeItem<LegendItemType>> => {
  const items: Record<TreeItemIndex, TreeItem<LegendItemType>> = {
    root: {
      index: "root",
      canMove: true,
      isFolder: true,
      children: config.MapChildren,
      data: {
        ID: config.ID,
        MapChildren: config.MapChildren,
        Type: "MapLayerConfig",
        Key: config.Key,
      },
    },
  };

  const add = (item: GroupConfig | LayerConfig | SublayerConfig | LayerStyle) => {
    // We ignore layers marked as Excluded
    if (item.Type === "LAYER" && item.Exclude) return;

    let children: string[] = [];
    if (item.Type === "GROUP") children = item.Children;
    if (item.Type === "LAYER") children = [...(showStyles ? item.Styles : []), ...item.Children];
    if (item.Type === "SUBLAYER") children = showStyles ? item.Styles : [];
    if (item) {
      items[item.ID as TreeItemIndex] = {
        index: item.ID,
        canMove: true,
        children,
        isFolder: ["GROUP", "LAYER", "SUBLAYER"].includes(item.Type) && children.length > 0,
        data: item,
      };
    }

    children.forEach((id) => {
      const child = config.Data.entities[id];
      if (child) add(child);
    });
  };

  config.MapChildren.forEach((childId) => {
    const item = config.Data.entities[childId];
    if (item) add(item);
  });

  return items;
};

/**
 * Return an array consisting of all layer and sublayer configurations in the config.
 * Optionally a filterType can be provided to only return LAYERS or SUBLAYERS.
 */
export const selectLayersFromConfig = (config: Configuration, filterTypes = ["LAYER", "SUBLAYER"]): Array<LayerConfig | SublayerConfig> => {
  return Object.values(config.Data.entities).filter((entity) => entity?.Type && filterTypes.includes(entity.Type)) as Array<
    LayerConfig | SublayerConfig
  >;
};

/**
 * Return an array consisting of all group configurations in the config.
 *
 */
export const selectGroupsFromConfig = (config: Configuration): GroupConfig[] => {
  return Object.values(config.Data.entities).filter((entity) => entity?.Type === "GROUP") as GroupConfig[];
};

/**
 * Return an array consisting of all styles in the config.
 */
export const selectStylesFromConfig = (config: Configuration): LayerStyle[] => {
  return Object.values(config.Data.entities).filter((entity) => entity?.Type === "STYLE") as LayerStyle[];
};

/**
 * Generate the items for the Tree used to display the report cost map.
 *
 * The tree has an entry for each sublayer corresponding to the provided cost to serve method.
 */
export const generateCostMapLegendTreeItems = ({
  config,
  method,
}: {
  config: Configuration;
  method: CTSType;
}): Record<TreeItemIndex, TreeItem<LegendItemType | null>> => {
  const sublayerConfigs = selectLayersFromConfig(config).filter(
    (layerConfig) => layerConfig.Type === "SUBLAYER" && layerConfig.ID.includes(method)
  ) as SublayerConfig[];

  return {
    ...Object.fromEntries(
      sublayerConfigs.map((sublayerConfig) => [
        sublayerConfig.ID,
        {
          index: sublayerConfig.ID,
          canMove: true,
          isFolder: false,
          children: [],
          data: sublayerConfig,
        },
      ])
    ),
    root: {
      index: "root",
      isFolder: true,
      children: pickIDs(sublayerConfigs),
      data: null,
    },
  };
};
