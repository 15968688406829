import { ToolbarAction, ToolbarConfig } from "fond/map/Toolbar";

/**
 * Toolbar for manually drawing subareas
 */
export const drawToolbarConfig: ToolbarConfig = [
  { type: "mode", actions: [ToolbarAction.SIMPLE_SELECT, ToolbarAction.AUTO_CARVE, ToolbarAction.DRAW_POLYGON] },
  {
    type: "action",
    actions: [ToolbarAction.SNAP, ToolbarAction.CUT, ToolbarAction.SPLIT, ToolbarAction.UNION, ToolbarAction.COMBINE, ToolbarAction.UNCOMBINE],
  },
  { type: "action", actions: [ToolbarAction.UNDO, ToolbarAction.REDO, ToolbarAction.DELETE] },
];

/**
 * Toolbar for carving areas only
 */
export const carveToolbarConfig: ToolbarConfig = [
  { type: "mode", actions: [ToolbarAction.SIMPLE_SELECT, ToolbarAction.AUTO_CARVE] },
  { type: "action", actions: [ToolbarAction.IMPORT_GEOJSON] },
  { type: "action", actions: [ToolbarAction.UNDO, ToolbarAction.REDO, ToolbarAction.DELETE] },
];
