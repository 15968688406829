import { LayerFilterOption } from "fond/map/Legend/Adornments/LayerFilter";
import { ConfigurationUpsert, GroupConfig } from "fond/types";
import { FilterConfiguration, LayerStyle } from "fond/types/ProjectLayerConfig";

import { FamilialLayerConfig, groupConfigTemplate, layerConfigTemplate, makeBuckets, OverlayIds, sublayerTemplate } from "./configuration";

export enum SoilLayerId {
  MIN_BEDROCK_DEPTH = "soil-min-bedrock-depth",
}
export const soilLayerFilterOptions: LayerFilterOption[] = [{ ID: SoilLayerId.MIN_BEDROCK_DEPTH, Label: "Minimum Bedrock Depth", Type: "Option" }];
export const defaultSoilFilteredLayerIds = [SoilLayerId.MIN_BEDROCK_DEPTH];

const key = "soil";

/**
 * Create the Soil overlay configuration.
 */
export const soilOverlayConfiguration = (systemOfMeasurement: "imperial" | "metric"): { configs: ConfigurationUpsert; groupConfig: GroupConfig } => {
  const { config: minBedrockDepthConfig, descendants: minBedrockDepthDescendants } = createMinBedrockDepthLayer(systemOfMeasurement);

  const layerConfigs = [minBedrockDepthConfig];
  const descendants = [...minBedrockDepthDescendants];

  // Create the groups
  const soilGroupConfig = groupConfigTemplate(OverlayIds.SOIL, layerConfigs, "Soil", true, OverlayIds.ROOT);

  // Assemble everything together
  return { configs: [...descendants, ...layerConfigs], groupConfig: soilGroupConfig };
};

const createMinBedrockDepthLayer = (systemOfMeasurement: "imperial" | "metric"): FamilialLayerConfig => {
  const buckets = makeBuckets({
    layerId: SoilLayerId.MIN_BEDROCK_DEPTH,
    attribute: "brockdepmin_meter",
    labels:
      systemOfMeasurement === "imperial"
        ? ["0 - 1", "1 - 2", "2 - 3", "3 - 4", "4+"]
        : ["0 - 0.3", "0.3 - 0.61", "0.61 - 0.91", "0.91 - 1.22", "1.22+"],
    // The splits are based on how APB categorises diggable vs not diggable ground
    splits: [0.0, 0.3, 0.61, 0.91, 1.22, 3.05],
    colors: ["#af7963", "#eb9264", "#fdbb8b", "#fde3c7", "#fff8f2"],
    includeNullBucket: true,
  });

  const descendants = buckets.flatMap((bucket) => {
    const styles = createMinBedrockDepthStyles(bucket.id, bucket.color, bucket.filter, 0.6);
    const sublayerConfig = sublayerTemplate(bucket.id, key, SoilLayerId.MIN_BEDROCK_DEPTH, bucket.label, bucket.filter, styles);
    return [...styles, sublayerConfig];
  });

  const config = layerConfigTemplate(
    SoilLayerId.MIN_BEDROCK_DEPTH,
    key,
    `Minimum Bedrock Depth (${systemOfMeasurement === "imperial" ? "ft" : "m"})`,
    [],
    buckets.map(({ id }) => id),
    OverlayIds.SOIL
  );

  return { config, descendants };
};

const createMinBedrockDepthStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity ?? 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];
