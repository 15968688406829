import { Feature } from "geojson";

import { apiSlice } from "./apiSlice";

/**
 * Features API Slice
 */
export const featuresSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFeature: build.query<Feature, string>({
      query: (featureId: string) => `/v2/features/${featureId}`,
      providesTags: (result) => (result ? [{ type: "Feature", id: result.ID }] : []),
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetFeatureQuery, useLazyGetFeatureQuery } = featuresSlice;
