import { Box } from "@mui/material";

import { ProjectVersionField } from "fond/form/fields";
import { BaseMultiProject, Folder, Project } from "fond/types";
import { required } from "fond/utils/validation";

import ReportDesignWarning from "../ReportDesignWarning";

type ReportProjectVersionProps = {
  projects: (Project | BaseMultiProject)[];
  folders: Folder[];
  disabled?: boolean;
};

export const ReportProjectVersion: React.FC<ReportProjectVersionProps> = ({ projects, folders, disabled }: ReportProjectVersionProps) => {
  return (
    <>
      <Box mt={2} sx={{ display: "flex", flexDirection: "row" }}>
        <ProjectVersionField projects={[...projects]} folders={folders} disabled={disabled} projectValidator={required} versionValidator={required} />
      </Box>
      <ReportDesignWarning />
    </>
  );
};
