/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useField, useForm } from "react-final-form";
import { useUpdateEffect } from "react-use";
import { CellClassParams, CellClickedEvent, ColDef, ITooltipParams, ProcessCellForExportParams, ValueSetterParams } from "@ag-grid-community/core";
import { Settings } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";
import { clamp, isNumber } from "lodash";

import { multiProjectsSlice, versionsSlice } from "fond/api";
import { useAppDispatch } from "fond/utils/hooks";
import { createSteppedRange } from "fond/utils/number";
import { AgGrid, BlockSpinner } from "fond/widgets";
import { IconButtonCellRenderer } from "fond/widgets/AgGrid";

import { RevenueFormConfiguration } from "../../types";
import { mergeRevenueConfigurations, transformDemandToRevenue } from "../../util";

import CellEditor from "./CellEditor";
import HorizonColumnHeader from "./HorizonColumnHeader";
import RevenueAdvancedControlModal, { type AdvancedControlForm } from "./RevenueAdvancedControlModal";

const defaultColDef = {
  suppressHeaderMenuButton: true,
};

const isValidMinTakeRate = (params: CellClassParams | ITooltipParams) => isNumber(params.value) && params.data.TakeRateMax > params.value;
const isValidMaxTakeRate = (params: CellClassParams | ITooltipParams) => isNumber(params.value) && params.data.TakeRateMin < params.value;

// When copying values we want the raw data, not the valueFormatter data
const processCellForClipboard = (params: ProcessCellForExportParams) => {
  return params.value;
};

export type ReportRevenueFormFieldsProps = {
  disabled?: boolean;
};

const ReportRevenueFormFields: React.FC<ReportRevenueFormFieldsProps> = ({ disabled }: ReportRevenueFormFieldsProps) => {
  const revenueConfigFields = "ReportConfiguration.Revenue.RevenueConfigurations";
  const { change, getState, initialize } = useForm();
  const {
    input: { onChange, onBlur, value: revenueConfigurations },
  } = useField(revenueConfigFields);
  const {
    meta: { error },
  } = useField("ReportConfigurationValidation");
  const {
    input: { value: version },
  } = useField("Version");
  const {
    input: { value: project },
  } = useField("Project");
  const {
    input: { value: planningHorizon },
  } = useField("ReportConfiguration.PlanningHorizon");

  const dispatch = useAppDispatch();
  const revenueConfigurationsRef = useRef<RevenueFormConfiguration[] | null>(revenueConfigurations);
  const [isLoading, setIsLoading] = useState(false);
  const [advancedControlModalIndex, setAdvancedControlModalIndex] = useState<number | null>(null);

  const createSteppedRangeFromTakeRateValues = useCallback(
    (takeRateMin: number, takeRateMax: number) => {
      const min = takeRateMin / 100;
      const max = takeRateMax / 100;
      // We explicitly fetch the planning horizon from the form state
      // because the planning horizon gets closed over by the AG Grid component
      const steps = getState().values.ReportConfiguration?.PlanningHorizon;
      return createSteppedRange(min, max, steps, 4);
    },
    [getState]
  );

  // This effect is used to update the local state when the revenue configurations are updated
  // Primarily necessary when the form is placed in edit mode and the edit is cancelled, at
  // which point we reset the form and need to update the local state.
  useUpdateEffect(() => {
    revenueConfigurationsRef.current = revenueConfigurations;
  }, [revenueConfigurations]);

  useEffect(() => {
    if (!version && !project) {
      // the fields were cleared, so we should clear the revenue configurations
      revenueConfigurationsRef.current = null;
      return;
    }
    if (project.EntityType === "project" && !version) {
      // Don't take any action when the version is cleared by itself
      // It's a required field so the user _will_ have to select a value.
      // It also becomes `null` when the project is changed, so we don't want to
      // invalidate while we're in the process of fetching the verions for the project
      return;
    }
    async function doFetchDemandModel() {
      setIsLoading(true);
      let demandModelResult;
      if (project.EntityType === "project") {
        const { data } = await dispatch(versionsSlice.endpoints.getVersion.initiate(version?.ID));
        demandModelResult = data?.Architecture?.Demand;
      } else if (project.EntityType === "multi_project") {
        // Load the full multi project data
        const { data } = await dispatch(multiProjectsSlice.endpoints.getMultiProject.initiate(project.ID));
        demandModelResult = data?.Architecture?.Demand;
      } else {
        return;
      }
      setIsLoading(false);
      const revenueConfigs = transformDemandToRevenue(demandModelResult?.DemandConfigurations.filter(({ Ignore }) => !Ignore));
      const mergedRevenueConfigs = mergeRevenueConfigurations(revenueConfigurationsRef.current, revenueConfigs);
      revenueConfigurationsRef.current = mergedRevenueConfigs;

      // If the configurations are fetched without user interaction, we need to reset the form state
      // so we combine this revenue configuration with the initial values to re-initialize the form
      // Note that we _ONLY_ re-initialise the form if it is not dirty, this is what indicates that the
      // user has not interacted with the form yet.
      if (!getState().dirty) {
        onChange(mergedRevenueConfigs || []); // we still need to trigger the change so the value gets populated
        const { initialValues, values } = getState();
        initialize({
          ...initialValues,
          ReportConfiguration: { ...initialValues?.ReportConfiguration, Revenue: { RevenueConfigurations: mergedRevenueConfigs } },
        });
      } else {
        onChange(mergedRevenueConfigs || []);
      }
      onBlur();
    }
    doFetchDemandModel();
  }, [version, project, dispatch, onBlur, onChange, getState, initialize]);

  // When the planning horizon changes, we need to update the take rates for all rows
  useEffect(() => {
    if (revenueConfigurationsRef.current) {
      revenueConfigurationsRef.current.forEach((row, index) => {
        change(`${revenueConfigFields}[${index}].TakeRate`, createSteppedRangeFromTakeRateValues(row.TakeRateMin, row.TakeRateMax));
      });
    }
  }, [planningHorizon, change, createSteppedRangeFromTakeRateValues]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      { field: "AddressType", headerName: "Address Type", width: 125, suppressNavigable: true },
      {
        field: "Description",
        headerName: "Description",
        flex: 1,
        suppressNavigable: true,
      },
      {
        field: "CashFlow",
        headerName: "ARPU (p/m)",
        editable: !disabled,
        cellClass: (_: CellClassParams) => ["ag-cell-value-editable"],
        cellEditor: CellEditor,
        cellEditorParams: {
          startAdornment: "$",
        },
        width: 125,
        valueFormatter: ({ data }) => `$ ${data.CashFlow}`,
        valueSetter: (params: ValueSetterParams) => {
          const newValue = Number(params.newValue);
          if (params.newValue === null || Number.isNaN(newValue) || newValue < 0) return false;
          params.data.CashFlow = newValue;
          return true;
        },
      },
      {
        field: "TakeRateMin",
        headerName: "Take rate (min)",
        width: 125,
        editable: !disabled,
        cellClass: (params: CellClassParams) => {
          if (!isValidMinTakeRate(params)) return ["ag-cell-value-invalid", "ag-cell-value-editable"];
          return ["ag-cell-value-editable"];
        },
        cellEditor: CellEditor,
        cellEditorParams: {
          endAdornment: "%",
        },
        valueSetter: (params: ValueSetterParams) => {
          const newValue = clamp(Number(params.newValue), 0, 100);
          if (newValue >= 0) {
            params.data.TakeRateMin = newValue;
            [...params.data.TakeRate].splice(0, 1, newValue);
            return true;
          }
          return false;
        },
        valueFormatter: ({ data }) => `${data.TakeRateMin} %`,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (!isValidMinTakeRate(params)) return "Minimum take rate must be less than maximum take rate";
          return null;
        },
      },
      {
        field: "TakeRateMax",
        headerName: "Take rate (max)",
        width: 125,
        editable: !disabled,
        cellClass: (params: CellClassParams) => {
          if (!isValidMaxTakeRate(params)) return ["ag-cell-value-invalid", "ag-cell-value-editable"];
          return ["ag-cell-value-editable"];
        },
        cellEditor: CellEditor,
        cellEditorParams: {
          endAdornment: "%",
        },
        valueSetter: (params: ValueSetterParams) => {
          const newValue = clamp(Number(params.newValue), 0, 100);
          if (newValue >= 0) {
            params.data.TakeRateMax = newValue;
            [...params.data.TakeRate].splice(1, 1, newValue);
            return true;
          }
          return false;
        },
        valueFormatter: ({ data }) => `${data.TakeRateMax} %`,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (!isValidMaxTakeRate(params)) return "Maximum take rate must be greater than minimum take rate";
          return null;
        },
      },
      {
        field: "TmnlVal",
        headerComponent: HorizonColumnHeader,
        editable: !disabled,
        cellClass: (_: CellClassParams) => ["ag-cell-value-editable"],
        cellEditor: CellEditor,
        cellEditorParams: {
          startAdornment: "$",
        },
        width: 100,
        valueSetter: (params: ValueSetterParams) => {
          const newValue = Number(params.newValue);
          if (params.newValue === null || Number.isNaN(newValue) || newValue < 0) return false;
          params.data.TmnlVal = newValue;
          return true;
        },
      },
      {
        field: "",
        colId: "menu",
        cellRenderer: IconButtonCellRenderer,
        cellRendererParams: {
          icon: Settings,
          iconProps: {
            color: !planningHorizon || disabled ? "disabled" : "primary",
          },
          iconButtonProps: {
            disabled: planningHorizon < 1 || disabled,
          },
        },
        cellStyle: {
          // Style the cell as unselectable
          textOverflow: "unset",
          border: "none",
          backgroundColor: "transparent",
        },
        width: 55,
        wrapText: false,
        resizable: false,
        suppressNavigable: true,
        onCellClicked: (event: CellClickedEvent) => {
          if (planningHorizon > 0 && !disabled) {
            setAdvancedControlModalIndex(event.rowIndex);
          }
        },
      },
    ],
    [planningHorizon, disabled]
  );

  const handleAdvancedControlSubmit = (advancedControlFormValues: AdvancedControlForm, revenueConfigurationIndex: number) => {
    const { ApplyToAll, TakeRateMax, TakeRateMin, TakeRate } = advancedControlFormValues;

    const updateRevenueConfigValues = (index: number) => {
      change(`${revenueConfigFields}[${index}].TakeRateMin`, TakeRateMin);
      change(`${revenueConfigFields}[${index}].TakeRateMax`, TakeRateMax);
      change(`${revenueConfigFields}[${index}].TakeRate`, TakeRate);
    };

    if (ApplyToAll) {
      ((revenueConfigurations as RevenueFormConfiguration[]) ?? []).forEach((_, ind) => {
        updateRevenueConfigValues(ind);
      });
    } else {
      updateRevenueConfigValues(revenueConfigurationIndex);
    }
  };

  if (isLoading) {
    return <BlockSpinner />;
  }

  if (!revenueConfigurationsRef.current || (revenueConfigurationsRef.current || []).length === 0) {
    if (project.EntityType === "multi_project") {
      return <p>To configure the revenue, please select an architecture with a demand model configured.</p>;
    }
    return <p>To configure the revenue, please select a project version that has a demand model configured in its architecture.</p>;
  }

  if (revenueConfigurations?.length > 0) {
    return (
      <>
        <AgGrid
          columnDefs={columnDefs}
          rowData={revenueConfigurations}
          gridOptions={{
            defaultColDef,
            editType: "fullRow",
            cellSelection: true,
            processCellForClipboard,
            singleClickEdit: true,
            suppressHeaderFocus: true,
            suppressCellFocus: false,
            stopEditingWhenCellsLoseFocus: true,
            domLayout: "autoHeight",
            sideBar: false,
            rowGroupPanelShow: "never",
            pagination: false,
            getContextMenuItems: () => [],
            onRowValueChanged: (event) => {
              const { rowIndex, data, api } = event;
              const { CashFlow, TakeRateMin, TakeRateMax, TakeRate, TmnlVal } = data;
              const prefix = `${revenueConfigFields}[${rowIndex}]`;

              change(`${prefix}.CashFlow`, CashFlow);
              change(`${prefix}.TakeRateMin`, TakeRateMin);
              change(`${prefix}.TakeRateMax`, TakeRateMax);
              change(`${prefix}.TmnlVal`, TmnlVal);

              const min = TakeRateMin / 100;
              const max = TakeRateMax / 100;
              // if the take rate has changed, we need to update the stepped range
              if (TakeRate[0] !== min || TakeRate.at(-1) !== max) {
                change(`${prefix}.TakeRate`, createSteppedRangeFromTakeRateValues(TakeRateMin, TakeRateMax));
              }
              // We triggger the onBlur to re-validate the form after a row has updated
              // and then a refresh to make sure cell styles are updated (to show validation)
              onBlur();
              api.refreshCells({ force: true });
            },
            tooltipShowDelay: 500,
            tooltipMouseTrack: true,
          }}
          variant="outlined"
        />
        <FormHelperText error sx={{ mt: 1 }}>
          {error}
        </FormHelperText>

        {advancedControlModalIndex !== null && (
          <RevenueAdvancedControlModal
            open
            onClose={() => setAdvancedControlModalIndex(null)}
            TakeRate={revenueConfigurationsRef.current[advancedControlModalIndex].TakeRate}
            TakeRateMin={revenueConfigurationsRef.current[advancedControlModalIndex].TakeRateMin}
            TakeRateMax={revenueConfigurationsRef.current[advancedControlModalIndex].TakeRateMax}
            PlanningHorizon={planningHorizon}
            onAdvancedControlSubmit={(advancedControlFormValues) => handleAdvancedControlSubmit(advancedControlFormValues, advancedControlModalIndex)}
          />
        )}
      </>
    );
  }

  return null;
};

export default ReportRevenueFormFields;
