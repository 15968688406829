import React, { useEffect } from "react";
import { useField, useForm } from "react-final-form";
import { usePrevious } from "react-use";
import { DateRange, Delete } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

import { SelectField, TextField } from "fond/form/fields";
import { NumericField } from "fond/form/fields/NumericField";
import { normalizeInteger } from "fond/form/util";
import { OverheadCost } from "fond/types";
import { compose, maximum, minimum, required } from "fond/utils/validation";

import { TableCell, TableRow } from "./OverheadCostAllocation.styles";

interface IProps {
  values: OverheadCost;
  index: number;
  deleteRow(): void;
  disabled?: boolean;
}

const OverheadCostAllocationRow: React.FC<IProps> = ({ values, index, deleteRow, disabled }) => {
  const { change } = useForm();
  const overheadCostFieldNamePrefix = `ReportConfiguration.CostConfiguration.Costs[${index}]`;
  const {
    input: { value: planningHorizon },
  } = useField("ReportConfiguration.PlanningHorizon");
  const prevPlanningHorizon = usePrevious(planningHorizon);

  const timesteps = Array.from(Array(planningHorizon), (_, ind) => ({ value: ind, displayValue: `Timestep ${ind + 1}` }));

  // Reset timestep values in the form when planningHorizon is changed
  useEffect(() => {
    // This assures that the reset of values is not triggered on first render when planningHorizon value is initially undefined
    if (prevPlanningHorizon && planningHorizon !== prevPlanningHorizon) {
      if (values.Cost.Type === "fixed_one_off") {
        change(`${overheadCostFieldNamePrefix}.Cost.Timestep`, "");
      }
      if (values.Cost.Type === "fixed_recurring" && values.Cost.TimestepStart) {
        const { TimestepStart, ...costValues } = values.Cost;
        change(`${overheadCostFieldNamePrefix}.Cost`, costValues);
      }
    }
  }, [planningHorizon]);

  return (
    <TableRow data-testid="overhead-cost-row">
      <TableCell>
        <Box height="center" />
        <TextField
          name={`${overheadCostFieldNamePrefix}.Name`}
          disabled={disabled}
          fullWidth
          size="small"
          validate={required}
          hideError
          sx={{ mt: 0.5 }}
        />
      </TableCell>
      <TableCell>
        <SelectField
          name={`${overheadCostFieldNamePrefix}.Cost.Type`}
          disabled={disabled}
          fullWidth
          size="small"
          options={[
            { value: "fixed_one_off", displayValue: "Fixed (one-off)" },
            { value: "fixed_recurring", displayValue: "Fixed (recurring)" },
          ]}
          validate={required}
          hideError
        />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1.5}>
          <Box width={90}>
            <NumericField
              name={`${overheadCostFieldNamePrefix}.Cost.CashFlow`}
              disabled={disabled}
              fullWidth
              size="small"
              validate={required}
              hideError
              min={0}
              sx={{ mt: 0.5 }}
            />
          </Box>
          {values.Cost.Type === "fixed_one_off" && (
            <>
              <Typography>on</Typography>
              <Box flex={1}>
                <SelectField
                  name={`${overheadCostFieldNamePrefix}.Cost.Timestep`}
                  disabled={disabled || timesteps.length === 0}
                  fullWidth
                  size="small"
                  options={timesteps}
                  startAdornment={<DateRange color="action" fontSize="small" />}
                  validate={required}
                  hideError
                />
              </Box>
            </>
          )}
          {values.Cost.Type === "fixed_recurring" && (
            <>
              <Typography>every</Typography>
              <Box width={45}>
                <TextField
                  name={`${overheadCostFieldNamePrefix}.Cost.TimestepFrequency`}
                  disabled={disabled}
                  fullWidth
                  size="small"
                  hideError
                  validate={compose(...[required, minimum(1), maximum(planningHorizon)])}
                  fieldProps={{ parse: normalizeInteger }}
                  sx={{ mt: 0.5 }}
                />
              </Box>
              <Typography>Quarters</Typography>
              <Box flex={1}>
                <SelectField
                  name={`${overheadCostFieldNamePrefix}.Cost.TimestepStart`}
                  disabled={disabled || timesteps.length === 0}
                  fullWidth
                  displayEmpty
                  placeholder="Starting date"
                  size="small"
                  options={timesteps}
                  startAdornment={<DateRange color="action" fontSize="small" />}
                />
              </Box>
            </>
          )}
          <IconButton color="primary" size="small" onClick={deleteRow} disabled={disabled}>
            <Delete />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default OverheadCostAllocationRow;
